import { FilterService } from "primereact/api";
import {
    getInspectionAndGradeData,
    getInternalGradeData
} from "../../services/FleetDataService";
import {
    getDistinctItems,
    returnAIGrade,
    toVesselTypeWithCount
} from "../../utils/FleetHelper";
import {
    addCustomFieldFleetFiltersToData,
    mutateFleetDataWithInspections
} from "../../utils/FleetTableDataHelper";
import {
    getUserSessionStorageItem,
    storeUserSessionStorageItem
} from "../../utils/UserSessionStorage";
import {
    selectFleet,
    selectFleetDataItems,
    selectInspectionAndGradeData,
    selectShowStatsTilesPanel
} from "../selectors/fleetSelectors";
import {
    selectUser,
    selectUserDetails,
    selectUserHasCapability,
    selectUserToken
} from "../selectors/userSelectors";
import {
    setDistinctCountriesOfBuild,
    setDistinctFlags,
    setDistinctTechnicalManagers,
    setDistinctVessels,
    setDistinctVesselsWithCount,
    setDistinctVesselTypes,
    setFleetDataItems,
    setInspectionAndGradeData,
    setInternalGradeData,
    setInspectionAndGradeDataError,
    setInspectionAndGradeDataLoading,
    setLoading,
    setMarketDataFilteringOptions,
    setShowStatsTilesPanel,
    setStatTileConfigs
} from "../slices/fleetSlice";
import {
    setAiGradingAccess,
    setAiGradingActive
} from "../slices/userSlice";
import { AppThunk } from "../store";
import {
    getStoredAdvancedFiltersState,
    setupAdvancedFilters
} from "./advancedFiltersThunks";
import { loadGlobalFilterStateFromLocalStorage } from "./filtersThunks";
import { cloneDeep } from "lodash";
import { FleetTableFields } from "../../components/FleetTable/FleetTableFields";
import { customRankFilter } from "../../components/FleetTable/FilterTemplates";
import { AIGradingAccess } from "../../types/AIGradingAccess";
import { selectDefects } from "../selectors/defectSelectors";
import { selectFleetGraph } from "../selectors/fleetGraphSelectors";
import { calculateFleetStatistics } from "../../utils/calculateStatistics";
import { getInspectionsCompletedInContractYear } from "../../utils/StatsHelper";
import { MAX_VISIBLE_TILES } from "../../utils/Constants";
import { setGraphContextFilter } from "../slices/filtersSlice";
import { InspectionAndGradeDataResponse } from "../../types/InspectionAndGradeDataResponse";
import { InternalGradeData } from "../../types/InternalGradeData";
import { setupFilteredDefects } from "./defectThunks";
import { mapScheduledInspections } from "../../utils/ScheduledHelper";
import { FleetDataItem } from "../../types/FleetDataItem";
import { Capability } from "../../types/Capability";

export const fetchInspectionAndGradeData =
    (): AppThunk<Promise<boolean>> =>
    async (dispatch, getState) => {
        const state = getState();
        const hasCapability = selectUserHasCapability(
            state,
            Capability.CAP_FLEETAPP_VIEW_FLEETS
        );
        const userToken = selectUserToken(state);
        
        if (hasCapability && userToken) {
            dispatch(setInspectionAndGradeDataLoading(true));
            dispatch(setInspectionAndGradeDataError(false));

            try {
                const fleetDataItems = selectFleetDataItems(state);
                const imos = fleetDataItems.map((fleetDataItem) => fleetDataItem.vessel.imo);

                const promises = [
                    getInspectionAndGradeData(userToken),
                    getInternalGradeData(imos.join(",")),
                ]

                const [inspections, internalGrades] = await Promise.all(promises);

                const inspectionData = [];

                if (inspections) {
                    const userDetails = selectUserDetails(state);

                    for (const key in inspections) {
                        const inspection = inspections[key];
                        const vesselData = fleetDataItems.find(item => String(item.vessel.imo) === inspection.shipImo);
                        const grades = returnAIGrade(inspection.shipImo, internalGrades);

                        if (vesselData) {
                        inspections[key].vessel = vesselData.vessel;
                        inspections[key].inspection = inspection.inspection;

                        const item = {
                            inspection : inspection,
                            vessel :  vesselData.vessel,
                            aiGrades: grades,
                            scoreTrends: inspection.scoreTrends,
                            scoreHistory: inspection.scoreHistory
                        } as FleetDataItem;

                        inspectionData.push(item);
                        }
                    }

                    const inspectionAndGradeData = {fleetDataItems: inspectionData};

                    const mappedInspections = mapScheduledInspections(
                        inspectionAndGradeData as InspectionAndGradeDataResponse,
                        userDetails
                    );

                    dispatch(
                        setInspectionAndGradeData(mappedInspections)
                    );
                }

                if (internalGrades) {
                    dispatch(setInternalGradeData(internalGrades as InternalGradeData[]));
                }
            } catch (e) {
                console.error(e);
                dispatch(setInspectionAndGradeDataError(true));
                dispatch(setInspectionAndGradeData({ fleetDataItems: [] }));
                dispatch(setInternalGradeData([]));
            } finally {
                dispatch(setInspectionAndGradeDataLoading(false));
            }
        }

        return true;
    };

export const toggleStatsTiles = (): AppThunk => async (dispatch, getState) => {
    const state = getState();
    const showStatsTilesPanel = selectShowStatsTilesPanel(state);
    const userDetails = selectUserDetails(state);

    dispatch(setShowStatsTilesPanel(!showStatsTilesPanel));

    if (userDetails?.userName) {
        const userKey = userDetails.userName;
        const key = "toggleStatsTiles";
        storeUserSessionStorageItem(userKey, key, !showStatsTilesPanel);
    }
};

export const setupFleetDataWithInspections =
    (): AppThunk<Promise<boolean>> =>
    async (dispatch, getState) => {
        const state = getState();
        const { aiGradingActive } = selectUser(state);
        const { data, inspectionAndGradeData, internalGradeData } =
            selectFleet(state);

        let fleetData = cloneDeep(data.fleetDataItems);
        let inspectionGradeItems = cloneDeep(
            inspectionAndGradeData.fleetDataItems
        );
        let internalGrades = cloneDeep(internalGradeData);


        mutateFleetDataWithInspections(
            fleetData,
            inspectionGradeItems,
            internalGrades,
            aiGradingActive
        );

        addCustomFieldFleetFiltersToData(fleetData);

        dispatch(setFleetDataItems(fleetData));

        return true;
    };

export const setupDistinctFleetData =
    (): AppThunk => async (dispatch, getState) => {
        const state = getState();
        const fleetDataItems = selectFleetDataItems(state);

        const vessels: string[] = [];
        const types: string[] = [];
        const managers: string[] = [];
        const countries: string[] = [];
        const flags: string[] = [];

        fleetDataItems.forEach((fleetDataItem) => {
            vessels.push(fleetDataItem.vessel.vesselName);
            types.push(fleetDataItem.vessel.type);
            managers.push(fleetDataItem.vessel.technicalManager);
            countries.push(fleetDataItem.vessel.countryOfBuild);
            flags.push(fleetDataItem.vessel.flag);
            vessels.push(fleetDataItem.vessel.vesselName);
        });

        dispatch(setDistinctVessels(getDistinctItems(vessels)));
        dispatch(setDistinctVesselTypes(getDistinctItems(types)));
        dispatch(setDistinctTechnicalManagers(getDistinctItems(managers)));
        dispatch(setDistinctCountriesOfBuild(getDistinctItems(countries)));
        dispatch(setDistinctFlags(getDistinctItems(flags)));

        const distinctVesselsWithCount = Array.from(
            new Set(toVesselTypeWithCount(fleetDataItems))
        )
            .filter(Boolean)
            .sort((a, b) => a.vesselType.localeCompare(b.vesselType));
        dispatch(setDistinctVesselsWithCount(distinctVesselsWithCount));
    };

export const setupFleetData =
    (): AppThunk<Promise<boolean>> => async (dispatch, getState) => {
        const state = getState();
        const { data, inspectionAndGradeData } = selectFleet(state);
        const userDetails = selectUserDetails(state);

        FilterService.register(
            `custom_${FleetTableFields.RANK}`,
            (value: any, filter: any) =>
                customRankFilter(
                    value,
                    filter,
                    data.fleetDataItems.filter((x) => x.gradeFilterField).length
                )
        );

        if (userDetails) {
            if (userDetails.groupDetails) {
                dispatch(
                    setAiGradingAccess(
                        userDetails.groupDetails.aiGradingAccess ?? AIGradingAccess.OFF
                    )
                );

                dispatch(
                    setAiGradingActive(
                        localStorage.getItem("ai-grading-active") === "true"
                    )
                );
            }

            const graphContextFilter = getUserSessionStorageItem(
                "user",
                "graphContextFilter"
            );
            dispatch(
                setGraphContextFilter(
                    graphContextFilter ? Number(graphContextFilter) : 0
                )
            );

            await dispatch(setupFleetDataWithInspections());

            dispatch(loadGlobalFilterStateFromLocalStorage());
            dispatch(setupDistinctFleetData());

            const marketDataFilteringOptionsResponse = userDetails.filterData;
            dispatch(
                setMarketDataFilteringOptions(
                    marketDataFilteringOptionsResponse
                )
            );
            dispatch(
                setupAdvancedFilters(
                    inspectionAndGradeData,
                    marketDataFilteringOptionsResponse
                )
            );
            dispatch(getStoredAdvancedFiltersState());
            dispatch(setupFilteredDefects(true));
        }

        dispatch(setLoading(false));

        return true;
    };


export const setupFleetStatsData =
    (): AppThunk => async (dispatch, getState) => {
        const state = getState();
        const inspectionAndGradeData = selectInspectionAndGradeData(state);
        const userDetails = selectUserDetails(state);
        const defects = selectDefects(state);
        const { subgradeData, subgradeBackingData } = selectFleetGraph(state);

        if (
            inspectionAndGradeData &&
            userDetails &&
            defects &&
            subgradeData &&
            subgradeBackingData
        ) {
            const fleetStatistics = calculateFleetStatistics(
                inspectionAndGradeData.fleetDataItems,
                subgradeData,
                subgradeBackingData,
                defects
            );

            const inspectionsCompletedInContractYear =
                await getInspectionsCompletedInContractYear();
            if (inspectionsCompletedInContractYear) {
                fleetStatistics.unshift(inspectionsCompletedInContractYear);
            }

            dispatch(
                setStatTileConfigs(fleetStatistics.slice(0, MAX_VISIBLE_TILES))
            );
        } else {
            dispatch(setStatTileConfigs([]));
        }
    };
