import { useCallback, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/storeHooks";
import {
    selectLoadingList,
    selectSelectedList,
    selectUserLists
} from "../../store/selectors/userSelectors";
import { SelectButton, SelectButtonChangeEvent } from "primereact/selectbutton";
import { selectList } from "../../store/thunks/listThunks";
import "./ListPicker.css";
import { classNames } from "primereact/utils";
import { Tooltip } from "primereact/tooltip";
import { useTranslation } from "react-i18next";
import { ListSelectItem } from "../../types/List";

export const ListPicker = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation("locale");

    const userLists = useAppSelector(selectUserLists);
    const selected = useAppSelector(selectSelectedList);
    const loading = useAppSelector(selectLoadingList);

    const options = useMemo(() => {
        const listOptions: ListSelectItem[] = [];
    
        if (userLists) {
            const listTypes = [
                {
                    type: "general",
                    label: t("pageHeader.monitoring"),
                    found: false
                },
                {
                    type: "selling",
                    label: t("pageHeader.selling"),
                    found: false
                },
                {
                    type: "buying",
                    label: t("pageHeader.buying"),
                    found: false
                }
            ];
    
            userLists?.lists?.forEach((list) => {
                const listType = listTypes.find((type) => type.type === list.type);
    
                if (listType) {
                    listType.found = true;
    
                    const listLabel =
                        list.label === "My Fleet" ? "Monitoring" : list.label;
    
                    listOptions.push({
                        label: listLabel,
                        type: listType.type,
                        value: list.id,
                        isPlaceholder: !list.vessels?.length
                    });
                }
            });
    
            listTypes.forEach((listType) => {
                if (!listType.found) {
                    listOptions.push({
                        label: listType.label,
                        type: listType.type,
                        value: "",
                        isPlaceholder: true
                    });
                }
            });
    
            listOptions.sort((a, b) => a.label.localeCompare(b.label));
            listOptions.sort(
                (a, b) =>
                    listTypes.findIndex((listType) => listType.type === a.type) -
                    listTypes.findIndex((listType) => listType.type === b.type)
            );
        }
    
        return listOptions;
    }, [userLists]);
    
        

    const onChange = useCallback(
        (event: SelectButtonChangeEvent) => {
            if (event.value && event.value !== selected) {
                dispatch(selectList(event.value));
            }
        },
        [selected]
    );

    const selectButtonTemplate = (option: any) => {
        if (option.isPlaceholder) {
            return (
                <>
                    <Tooltip
                        target={`.placeholder-list-${option.type}`}
                        className="placeholder-tooltip"
                    >
                        <i className="pi pi-lock mr-2 text-sm"></i>
                        {t("pageHeader.contactUsTooltip", {
                            list: option.label
                        })}
                    </Tooltip>

                    <span
                        className={`placeholder-list placeholder-list-${option.type}`}
                        data-pr-position="bottom"
                        data-pr-at="center bottom+15"
                        data-cy={`list-button-${option.label}`}
                    >
                        {option.label}
                    </span>
                </>
            );
        }

        return (
            <span data-cy={`list-button-${option.label}`}>{option.label}</span>
        );
    };

    return (
        <SelectButton
            value={selected?.id}
            onChange={onChange}
            optionLabel="label"
            options={options}
            disabled={loading}
            className={classNames("list-picker", "ml-4 mr-4", {
                "list-picker-loading": loading
            })}
            itemTemplate={selectButtonTemplate}
        />
    );
};
