import { useTranslation } from "react-i18next";
import Plot from "react-plotly.js";
import { ProgressSpinner } from "primereact/progressspinner";
import { useCallback, useEffect, useMemo, useState } from "react";
import { getDefectsGraphData } from "../../utils/DefectGraphHelper";
import { Config, Layout, PlotData, PlotMouseEvent } from "plotly.js";
import { ClearSelectedVesselButton } from "../Shared/ClearSelectedVesselButton";
import "./DefectsGraph.css";
import { useAppDispatch, useAppSelector } from "../../hooks/storeHooks";
import {
    selectFilteredDefects,
    selectSelectedVessel
} from "../../store/selectors/defectSelectors";
import { selectDefectGraphContextFilter } from "../../store/selectors/filtersSelectors";
import { SubgradeGraphFilterType } from "../GraphFilterSelector/GraphFilterSelector";
import { DefectTableFields } from "../DefectsTable/DefectTableFields";
import { defaultDefectsFiltersConfig } from "../DefectsTable/Filters/FilterTemplates";
import LOCAL_TABLE_CONFIG_KEY from "../../types/TableLocalConfigKeys";
import {
    clearAllFilters,
    resetTableFilters
} from "../../store/thunks/filtersThunks";
import {
    setActiveTab,
    setSelectedVessel
} from "../../store/slices/defectSlice";
import { DefectTabs } from "../../types/DefectTabs";
import { FilterMatchMode } from "primereact/api";
import { convert } from "html-to-text";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { setDefectGraphContextFilter } from "../../store/slices/filtersSlice";
import {
    mapToFilteringOptions,
    subgradeGraphFilteringOptions
} from "../FilterButtonRow/GraphFilterOptions";
import { Button } from "primereact/button";

type DefectsGraphProps = {
    isLoading: boolean;
};

export const DefectsGraph = (props: DefectsGraphProps) => {
    const { isLoading } = props;

    const { t } = useTranslation("locale");
    const dispatch = useAppDispatch();

    const filteredDefects = useAppSelector(selectFilteredDefects);
    const selectedVessel = useAppSelector(selectSelectedVessel);

    const [categoryArray, setCategoryArray] = useState<string[]>([]);
    const [data, setData] = useState<Partial<PlotData>[]>([]);
    const [maxValue, setMaxValue] = useState(0);
    const defectGraphContextFilter = useAppSelector(
        selectDefectGraphContextFilter
    );
    const [graphTitle, setGraphTitle] = useState("");
    const [filterOptions, setFilterOptions] = useState<
        Array<{ name: string; code: number }>
    >([]);

    useEffect(() => {
        const { defectFilterValues, defectsGraphData, defectsMaxValue } =
            getDefectsGraphData(
                filteredDefects,
                selectedVessel,
                defectGraphContextFilter
            );
        const { defectFilterValues: originalDefectOrder } = getDefectsGraphData(
            filteredDefects,
            selectedVessel,
            0
        );

        let currentTitle = t("defectsGraph.defectsPreamble");

        if (defectGraphContextFilter !== SubgradeGraphFilterType["All Areas"]) {
            currentTitle =
                t("defectsGraph.defectsPreambleByArea") +
                SubgradeGraphFilterType[defectGraphContextFilter];
        }

        setGraphTitle(currentTitle);
        setCategoryArray(defectFilterValues);
        setData(defectsGraphData);
        setMaxValue(defectsMaxValue);
        originalDefectOrder.reverse().unshift("All Areas");

        const options = mapToFilteringOptions(
            originalDefectOrder,
            subgradeGraphFilteringOptions()
        );
        setFilterOptions(options);
    }, [filteredDefects, selectedVessel, defectGraphContextFilter]);

    const axisTicks = useMemo(() => {
        if (maxValue <= 4 && maxValue > 0) {
            const arr = [];
            for (let i = 1; i <= maxValue; i++) {
                arr.push(i);
            }
            return arr;
        }
        return undefined;
    }, [data]);

    const layout = useMemo(() => {
        const layout: Partial<Layout> = {
            autosize: true,
            xaxis: {
                autorange: true,
                showgrid: true,
                visible: true,
                fixedrange: true,
                tickvals: axisTicks,
                gridcolor: "#B0E2E4",
                tickfont: {
                    color: "#777",
                    size: 12
                }
            },
            yaxis: {
                autorange: true,
                showgrid: false,
                automargin: true,
                categoryarray: categoryArray,
                categoryorder: "array",
                fixedrange: true,
                tickfont: {
                    color: "#555",
                    size: 14
                }
            },
            margin: {
                pad: 10,
                l: 100,
                t: 20,
                b: 20,
                r: 0
            },
            barmode: "stack",
            font: {
                family: "Open Sans"
            },
            showlegend: false,
            plot_bgcolor: "#FCFCFC",
            hovermode: "y unified",
            hoverlabel: {
                bgcolor: "#1C1940",
                bordercolor: "#1C1940",
                font: {
                    color: "#FFF",
                    size: 14
                }
            }
        };

        return layout;
    }, [data]);

    const config = useMemo(() => {
        const graphConfig: Partial<Config> = {
            displayModeBar: true,
            responsive: true,
            showTips: false,
            displaylogo: false,
            modeBarButtonsToRemove: [
                "pan2d",
                "zoom2d",
                "autoScale2d",
                "resetScale2d",
                "lasso2d",
                "select2d"
            ]
        };
        return graphConfig;
    }, []);

    const onClick = useCallback((event: PlotMouseEvent) => {
        if (event.points.length) {
            const { y } = event.points[0];
            if (y && typeof y === "string") {
                const filterValue = convert(y, { decodeEntities: false });
                const isTechManagerClick =
                    !SubgradeGraphFilterType[
                        filterValue as unknown as SubgradeGraphFilterType
                    ];

                const selectedGradingArea = isTechManagerClick
                    ? SubgradeGraphFilterType[defectGraphContextFilter]
                    : filterValue;

                dispatch(
                    resetTableFilters(LOCAL_TABLE_CONFIG_KEY.DEFECTS, {
                        ...defaultDefectsFiltersConfig,
                        [DefectTableFields.TECHNICAL_MANAGER]: {
                            value: isTechManagerClick ? [filterValue] : [],
                            matchMode: FilterMatchMode.IN
                        },
                        [DefectTableFields.GRADING_AREA_FIELD]: {
                            value: [selectedGradingArea],
                            matchMode: FilterMatchMode.IN
                        }
                    })
                );

                dispatch(setActiveTab(DefectTabs.defects));
            }
        }
    }, []);

    const onChangeFilter = useCallback((event: DropdownChangeEvent) => {
        dispatch(setDefectGraphContextFilter(event.value));
    }, []);

    const onClickClear = useCallback(() => {
        dispatch(clearAllFilters());
        dispatch(setSelectedVessel(""));
        dispatch(setDefectGraphContextFilter(0));
    }, []);

    if (isLoading) {
        return (
            <div className="flex h-screen justify-items-center align-items-center">
                <ProgressSpinner />
            </div>
        );
    }

    return (
        <>
            <div className="flex flex-row justify-content-between mb-4">
                {selectedVessel ? (
                    <ClearSelectedVesselButton />
                ) : (
                    <Button
                        id={"clearAllFilters"}
                        className={
                            "p-button p-button-outlined p-button-secondary secondary-outlined-button"
                        }
                        data-cy="clear-all-filters"
                        onClick={onClickClear}
                    >
                        {t("fleetGraph.clear")}
                    </Button>
                )}
                <div className="flex flex-row align-items-center">
                    <p>{t("defectInsights.show")}</p>
                    <Dropdown
                        value={defectGraphContextFilter}
                        onChange={onChangeFilter}
                        options={filterOptions}
                        className="md:w-15rem ml-3"
                        optionLabel="name"
                        optionValue="code"
                        data-cy="defects-graph-dropdown"
                    />
                </div>
            </div>
            <div className="flex flex-row justify-content-between align-items-center">
                <div className="flex flex-row justify-content-between align-items-center">
                    <p className="defect-graph-title">{graphTitle}</p>
                </div>
                <div className="flex flex-row">
                    <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <circle cx="8" cy="8" r="8" fill="#EF5A14" />
                    </svg>
                    <p className="defect-graph-legend-title mr-3">
                        {t("defectsGraph.highPriority")}
                    </p>
                    <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <circle cx="8" cy="8" r="8" fill="#E5940E" />
                    </svg>
                    <p className="defect-graph-legend-title mr-3">
                        {t("defectsGraph.mediumPriority")}
                    </p>
                    <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <circle cx="8" cy="8" r="8" fill="#F8D311" />
                    </svg>
                    <p className="defect-graph-legend-title">
                        {t("defectsGraph.lowPriority")}
                    </p>
                </div>
            </div>
            <Plot
                useResizeHandler={true}
                data={data}
                layout={layout}
                config={config}
                className="defects-graph"
                onClick={onClick}
            />
            <p className="mt-4 defect-graph-xaxis-title">
                {t("defectsGraph.numberOfDefects")}
            </p>
        </>
    );
};
