import { getUserCapabilities, isIdUser } from "../../services/UserService";
import { Capability } from "../../types/Capability";
import { selectPersistTimestamp, selectUserId } from "../selectors/userSelectors";
import {
    setHasFleetAccess,
    setPersistTimestamp,
    setUserCapabilities,
    setUserId
} from "../slices/userSlice";
import { AppThunk, persistor } from "../store";
import { AmplifyUser } from "@aws-amplify/ui";
import { fetchOrder, fetchOrderByList } from "./fetchOrder";
import { fetchScreeningData } from "./screeningThunks";
import { fetchTokenData } from "./tokenThunks";
import { PERSIST_TIMEOUT } from "../../utils/Constants";
import { fetchNotifications } from "./notificationsThunks";

export const initFetchData =
    (user: AmplifyUser): AppThunk =>
    async (dispatch, getState) => {
        const persistTimestamp = selectPersistTimestamp(getState());

        const currentTimestamp = new Date().getTime();

        if (persistTimestamp) {
            if (currentTimestamp - persistTimestamp > PERSIST_TIMEOUT) {
                await persistor.purge();
            }
        }

        const state = getState();
        const userId = selectUserId(state);
        
        if (user && !userId) {
            persistor.pause();
            const currentTimestamp = new Date().getTime();

            dispatch(setUserId(user.username));

            const userCapabilities = await getUserCapabilities(user.username);

            dispatch(setUserCapabilities(userCapabilities));
            dispatch(setHasFleetAccess(isIdUser(userCapabilities)));

            const isTechManager = userCapabilities.includes(
                Capability.CAP_FLEETAPP_VIEW_RECTIFICATIONS
            );

            await dispatch(fetchDataByPath(isTechManager, false));

            persistor.persist();
            
            dispatch(fetchScreeningData());
            dispatch(fetchTokenData());
            dispatch(fetchNotifications());
            dispatch(setPersistTimestamp(currentTimestamp));
        }
    };

export const fetchDataByPath =
    (
        techManager: boolean,
        byList: boolean
    ): AppThunk<Promise<boolean>> =>
    async (dispatch) => {
        let path = window.location.href.split("/")[1] ?? "";

        if (techManager) {
            path = "techManager";
        }

        if (path === "psc" || path === "screening") {
            path = "";
        }

        let fetchOrderByPath = fetchOrder[path];

        if (byList) {
            fetchOrderByPath = fetchOrderByList[path];
        }

        for (const appThunks of fetchOrderByPath) {
            const promises = appThunks.map((appThunk) => dispatch(appThunk));
            await Promise.all(promises);
        }

        return true;
    };
