import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserDetails } from '../../types/UserDetails';
import { AIGradingAccess } from '../../types/AIGradingAccess';
import { List, UserLists } from '../../types/List';
import persistReducer from 'redux-persist/es/persistReducer';
import storage from 'redux-persist/es/storage';
import { compressor } from '../../utils/UserSessionStorage';
import { PURGE } from 'redux-persist/es/constants';
import { GroupDetails } from '../../types/GroupDetails';

export interface UserState {
    userId: string | undefined;
    userCapabilities: string[];
    userDetails: UserDetails | undefined;
    userToken: string | undefined;
    hasFleetAccess: boolean | null,
    aiGradingAccess: AIGradingAccess;
    aiGradingActive: boolean;
    userLists: UserLists | undefined;
    selectedList: List | undefined;
    loadingList: boolean;
    persistTimestamp: number;
}

export const initialState: UserState = {
    userId: undefined,
    userCapabilities: [],
    userDetails: undefined,
    userToken: undefined,
    hasFleetAccess: null,
    aiGradingAccess: 0,
    aiGradingActive: false,
    userLists: undefined,
    selectedList: undefined,
    loadingList: false,
    persistTimestamp: 0,
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUserId: (state, action: PayloadAction<string | undefined>) => {
            state.userId = action.payload;
        },
        setUserCapabilities: (state, action: PayloadAction<string[]>) => {
            state.userCapabilities = action.payload;
        },
        setUserDetails: (state, action: PayloadAction<UserDetails | undefined>) => {
            state.userDetails = action.payload;
        },
        setUserToken: (state, action: PayloadAction<string | undefined>) => {
            state.userToken = action.payload;
        },
        setHasFleetAccess: (state, action: PayloadAction<boolean>) => {
            state.hasFleetAccess = action.payload;
        },
        setAiGradingAccess: (state, action: PayloadAction<AIGradingAccess>) => {
            state.aiGradingAccess = action.payload;
        },
        setAiGradingActive: (state, action: PayloadAction<boolean>) => {
            state.aiGradingActive = action.payload;
        },
        setUserLists: (state, action: PayloadAction<UserLists | undefined>) => {
            state.userLists = action.payload;
        },
        setSelectedList: (state, action: PayloadAction<List | undefined>) => {
            state.selectedList = action.payload;
        },
        setLoadingList: (state, action: PayloadAction<boolean>) => {
            state.loadingList = action.payload;
        },
        setPersistTimestamp: (state, action: PayloadAction<number>) => {
            state.persistTimestamp = action.payload;
        },
        updateGroupDetails: (state, action: PayloadAction<GroupDetails>) => {
            if (state.userDetails?.groupDetails) {
                state.userDetails.groupDetails = action.payload;
            }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(PURGE, () => initialState);
    }
});

export const { 
    setUserId,
    setUserCapabilities,
    setUserDetails,
    setUserToken,
    setHasFleetAccess,
    setAiGradingAccess,
    setAiGradingActive,
    setUserLists,
    setSelectedList,
    setLoadingList,
    setPersistTimestamp,
    updateGroupDetails
} = userSlice.actions;

const persistedReducer = persistReducer(
    {
        key: "user",
        storage,
        transforms: [compressor],
        blacklist: ["userId", "userDetails"]
    },
    userSlice.reducer
);

export default persistedReducer;
