import { updateGroup } from "../../services/GroupService";
import { selectUser } from "../selectors/userSelectors";
import { updateGroupDetails } from "../slices/userSlice";
import { AppThunk } from "../store";

export const updateGroupData =
    (data: Record<string, unknown>): AppThunk =>
    async (dispatch, getState) => {
        const { userDetails, userLists } = selectUser(
            getState()
        );

        if (data && userDetails?.groupDetails && userLists) {
            const group = userLists.group;

            if (group) {
                const updatedGroupDetails = mergeData(
                    userDetails.groupDetails,
                    data,
                    group.name
                );

                const updated = await updateGroup(
                    group.id,
                    updatedGroupDetails
                );

                if (updated) {
                    dispatch(updateGroupDetails(updatedGroupDetails));
                }
            }
        }
    };

const mergeData = (source: any, data: any, group: string) => {
    const updated = { ...source };

    const groupData = data[group];

    for (const key in groupData) {
        if (groupData[key]) {
            updated[key] = {
                ...updated[key],
                ...groupData[key]
            };
        }
    }

    if (data.attributesToDelete) {
        data.attributesToDelete.forEach((attribute: string) => {
            delete updated[attribute];
        });
    }

    return updated;
};
