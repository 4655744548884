import {ColumnEvent} from "primereact/column";
import { FleetDataItem } from "../../types/FleetDataItem";

const getTableDataFromEvent = (e: any) => {
    return e.props.tableProps.value;
}

export const onRiskEditComplete = (
    e: any,
    updateRowItem: (item: FleetDataItem) => void,
    onComplete: (data: Record<string, unknown>) => void
) => {
    let {rowData} = e;

    updateRowItem(rowData);

    const allTableData = getTableDataFromEvent(e);

    const riskLevelPost = createSpecialColumnPost(allTableData, "riskLevels", "riskLevel");
    
    onComplete(riskLevelPost);
};

export function createSpecialColumnPost(data: any, fieldName: string, rowDataFieldname: string) {
    const specialColumnPost: { [key: string]: any } = {};

    data.forEach((row: any) => {
        if (row.vessel.riskLevel !== 'None') {
            // remove grouping by group when multi-list out
            if (!specialColumnPost.hasOwnProperty(row.vessel.group)) {
                specialColumnPost[row.vessel.group] = {};
                specialColumnPost[row.vessel.group][fieldName] = {};
            }
            specialColumnPost[row.vessel.group][fieldName][row.vessel.imo] = row.vessel[rowDataFieldname];
        }
    })

    return specialColumnPost;
}

export const onCellEditCompleteGenerator = (
    fieldName: string,
    onComplete: (data: Record<string, unknown>) => void
) => {
    return (e: ColumnEvent) => {
        let {rowData, newValue} = e;
        rowData[fieldName] = newValue;

        const allTableData = getTableDataFromEvent(e);

        const dataType = rowData[`${fieldName}.dataType`] ?? undefined;
        const post = createPost(allTableData, fieldName, dataType);

        onComplete(post);
    }
}

export const onNotesEditComplete = (
    e: any,
    updateRowItem: (item: FleetDataItem) => void,
    onComplete: (data: Record<string, unknown>
) => void) => {
    let {rowData, newValue, field} = e;

    updateRowItem({
        ...rowData,
        [field]: newValue
    });

    const allTableData = getTableDataFromEvent(e);

    const notesPost = createSpecialColumnPost(allTableData, "notes", "notes");

    onComplete(notesPost);
};

export function createPost(data: any, fieldName: string, dataType?: string) {
    const post: { [key: string]: any } = {};

    data.forEach((row: any) => {
            // remove grouping by group when multi-list out
            if (!post.hasOwnProperty(row.vessel.group)) {
                post[row.vessel.group] = {};
                post[row.vessel.group][fieldName] = {};
            }
            post[row.vessel.group][fieldName][row.vessel.imo] = row[fieldName];
            if (dataType) {
                post[row.vessel.group][fieldName]["dataType"] = dataType;
            }
    });
    
    return post;
}