import { AppThunk } from "../store";
import { getInsightsAgeOfOpenDefectsData, getInsightsClosedRatioData, getInsightsCumulativeDefectData } from "../../services/DefectInsightsService";
import { setAgeOfOpenDefectsData, setAgeOfOpenDefectsError, setAgeOfOpenDefectsLoading, setClosedRatioData, setClosedRatioError, setClosedRatioLoading, setCumulativeDefectData, setCumulativeDefectError, setCumulativeDefectLoading } from "../slices/defectInsightsSlice";
import { selectUserToken } from "../selectors/userSelectors";

export const fetchInsightsData = (): AppThunk => async (dispatch) => {
    dispatch(fetchClosedRatios());
    dispatch(fetchCumulativeDefects());
    dispatch(fetchAgeOfOpenDefects());
};

export const fetchClosedRatios = (): AppThunk => async (dispatch, getState) => {
    const userToken = selectUserToken(getState());

    if (userToken) {
        try {
            const response = await getInsightsClosedRatioData(userToken);
    
            if (response?.length) {
                dispatch(setClosedRatioData(response));
            } else {
                throw Error('no data');
            }
        } catch (e) {
            console.error(e);
            dispatch(setClosedRatioError(true));
        } finally {
            dispatch(setClosedRatioLoading(false));
        }
    }
};

export const fetchCumulativeDefects = (): AppThunk => async (dispatch, getState) => {
    const userToken = selectUserToken(getState());

    if (userToken) {
        try {
            const response = await getInsightsCumulativeDefectData(userToken);
    
            if (response?.length) {
                dispatch(setCumulativeDefectData(response));
            } else {
                throw Error('no data');
            }
        } catch (e) {
            console.error(e);
            dispatch(setCumulativeDefectError(true));
        } finally {
            dispatch(setCumulativeDefectLoading(false));
        }
    }
};

export const fetchAgeOfOpenDefects = (): AppThunk => async (dispatch, getState) => {
    const userToken = selectUserToken(getState());
    
    if (userToken) {
        try {
            const response = await getInsightsAgeOfOpenDefectsData(userToken);
    
            if (response?.length) {
                dispatch(setAgeOfOpenDefectsData(response));
            } else {
                throw Error('no data');
            }
        } catch (e) {
            console.error(e);
            dispatch(setAgeOfOpenDefectsError(true));
        } finally {
            dispatch(setAgeOfOpenDefectsLoading(false));
        }
    }
};
