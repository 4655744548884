import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SharedDefectItem } from '../../types/SharedDefectItem';
import { TablePageState } from '../../types/Table';
import { PreviousRecipients } from '../../types/PreviousRecipients';

export interface SharedDefectsState {
    data: SharedDefectItem[];
    loading: boolean;
    error: boolean;
    page: TablePageState;
    previousRecipients: PreviousRecipients[];
}

export const initialState: SharedDefectsState = {
    data: [],
    loading: true,
    error: false,
    page: { row: 10, first: 0 },
    previousRecipients: [ { email: "", name: "" } ]
};

export const sharedDefectsSlice = createSlice({
    name: 'sharedDefects',
    initialState,
    reducers: {
        setData: (state, action: PayloadAction<SharedDefectItem[]>) => {
            state.data = action.payload;
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setError: (state, action: PayloadAction<boolean>) => {
            state.error = action.payload;
        },
        setPage: (state, action: PayloadAction<TablePageState>) => {
            state.page = action.payload
        },
        setPreviousRecipients: (state, action: PayloadAction<PreviousRecipients[]>) => {
            state.previousRecipients = action.payload
        },
    }
});

export const { setData, setLoading, setError, setPage, setPreviousRecipients } = sharedDefectsSlice.actions;

export default sharedDefectsSlice.reducer;
