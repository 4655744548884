import  "./FilterButtonRow.css";
import { Button } from "primereact/button";
import { ShowAllFiltersButton } from "../ShowAllFilters/ShowAllFiltersButton";
import { FilterIcon } from "../Icons/FilterIcon";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../hooks/storeHooks";
import { toggleOpenState } from "../../store/thunks/advancedFiltersThunks";
import { GraphFilterSelector } from "../GraphFilterSelector/GraphFilterSelector";
import {
    selectGraphContextFilter,
    selectSubGradeGraphContextFilter
} from "../../store/selectors/filtersSelectors";
import { setGraphContextFilter, setSubGradeGraphContextFilter } from "../../store/slices/filtersSlice";
import { useLocation } from "react-router-dom";
import { storeUserSessionStorageItem } from "../../utils/UserSessionStorage";
import { graphFilterDropdownOptions, mapToFilteringOptions, subgradeGraphFilteringOptions } from "./GraphFilterOptions";
import { useMemo } from "react";


export type FilterButtonContainerProps = {
    enableFilterButton: boolean;
    subgradeFilterOptions?: Array<string>;
}

export const FilterButtonRow = ({
    enableFilterButton,
    subgradeFilterOptions,
}: FilterButtonContainerProps) => {
    const {t} = useTranslation("locale");

    const location = useLocation();
    const graphPage = location.pathname.split("/")[1];

    const dispatch = useAppDispatch();
    const graphContextFilter = useAppSelector(selectGraphContextFilter);
    let subgradeGraphContextFilter = useAppSelector(selectSubGradeGraphContextFilter);

    const onClick = () => {
        dispatch(toggleOpenState());
    }

    const handleGraphFilterChange = (e: any) => {
        dispatch(setGraphContextFilter(e.value));
        storeUserSessionStorageItem("user", "graphContextFilter", e.value);
    }

    const handleSubgradeFilterChange = (e: any) => {
        dispatch(setSubGradeGraphContextFilter(e.value));
    }

    const graphFilterOptions = useMemo(() => graphFilterDropdownOptions(), []);
    const subgradeFilterItems = useMemo(() => subgradeGraphFilteringOptions(), []);

    const getGraphFilterSelector = () => {
        if (graphPage === "grade") {
            return (
                <GraphFilterSelector 
                    selectedValue={graphContextFilter}
                    onFilterChange={handleGraphFilterChange}
                    options={graphFilterOptions} />
            )
       }

        if (graphPage === "subgrades" && subgradeFilterOptions) {
            const items = mapToFilteringOptions(subgradeFilterOptions, subgradeFilterItems)
            items.unshift(subgradeFilterItems[0])

            // Stops the dropdown from displaying nothing if the graph is filtered too aggressively
            if (subgradeGraphContextFilter !== 0 && items.length === 1) {
                subgradeGraphContextFilter = 0;
            }
            return (
                <GraphFilterSelector 
                    selectedValue={subgradeGraphContextFilter}
                    onFilterChange={handleSubgradeFilterChange}
                    options={items} />
            )
        }

        return <></>;
    }

    return (
        <div className={"filter_button_row__button-container"}>
            <ShowAllFiltersButton />
            <div className="flex align-items-center">
                {getGraphFilterSelector()}
                {
                    enableFilterButton ?
                        <Button
                            className={`p-button p-button-primary w-14rem`}
                            label={t("benchmarkModal.benchmarkFilters")}
                            icon={FilterIcon}
                            onClick={onClick}
                        />
                        :
                        <></>
                }
            </div>
        </div>
    )
}
