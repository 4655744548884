import { getTranslationObject } from "../services/Translation";
import { FleetDataItem } from "../types/FleetDataItem";
import { Inspection } from "../types/Inspection";
import { InspectionAndGradeDataResponse } from "../types/InspectionAndGradeDataResponse";
import { UserDetails } from "../types/UserDetails";

const INSPECTION_STATUS_FULL_REPORT = 7;

export const mapScheduledInspections = (
    inspections: InspectionAndGradeDataResponse,
    userDetails: UserDetails | undefined,
) => {
    if (userDetails?.groupDetails) {
        
        const groupScheduledInspectionInterval =
            userDetails.groupDetails.scheduledInspectionInterval;

        const scheduledData = userDetails.scheduledData?.length ? userDetails.scheduledData[0].vessels : {};

        inspections.fleetDataItems.forEach((fleetDataItem) => {
            const { imo } = fleetDataItem.vessel;

            let scheduledDate = scheduledData[imo]?.scheduled;

            const calculatedDate = calculateScheduledDate(
                scheduledDate,
                fleetDataItem.inspection,
                groupScheduledInspectionInterval
            );

            if (calculatedDate) {
                scheduledDate = calculatedDate.toLocaleDateString("en-GB");
            }

            fleetDataItem.scheduledDate = scheduledDate;

            if (scheduledDate && !fleetDataItem.inspection?.inspectionDue) {
                fleetDataItem.inspection.inspectionDue = scheduledDate
            }
        });
    }

    return inspections;
};

export const calculateScheduledDate = (
    configScheduledDate: string | null,
    inspection: Inspection | undefined,
    scheduledInspectionInterval?: number
) => {
    if (configScheduledDate) {
        const scheduledDate = parseDate(configScheduledDate);

        if (
            inspection?.inspectionDue &&
            inspection?.status === INSPECTION_STATUS_FULL_REPORT &&
            !inspection?.isScheduled
        ) {
            const inspectionDue = new Date(inspection.inspectionDue);
            const inspectionDuePlusInterval = getDateWithInterval(
                inspection,
                scheduledInspectionInterval
            );
    
            if (
                scheduledDate &&
                inspectionDuePlusInterval &&
                (scheduledDate <= inspectionDue ||
                    scheduledDate <= inspectionDuePlusInterval)
            ) {
                return inspectionDuePlusInterval;
            }
        }
    
        return scheduledDate;
    } else {
        if (inspection) {
          return getDateWithInterval(inspection, scheduledInspectionInterval);
        }
    
        return null;
    }
};

const getDateWithInterval = (inspection: Inspection, interval?: number) => {
    if (interval && !isNaN(interval)) {
        let date = inspection.inspectionDue ?? inspection.lastInspected;

        if (date) {
            const updatedDate = new Date(date);
            updatedDate.setDate(updatedDate.getDate() + interval);
            return updatedDate;
        }
    }

    return null;
};
  
export const parseDate = (date: string) => {
    const split = date.split(" ")[0].split("/").map(Number);
    return new Date(split[2], split[1] - 1, split[0]);
};

export const mapInspectionDueBody = (rowData: FleetDataItem | undefined) => {
    if (rowData) {
        const bodyData = {...rowData, translationFunction: getTranslationObject()};

        if (bodyData.scheduledDate) {
            bodyData.scheduledDate = parseDate(bodyData.scheduledDate).toISOString();
        }

        return bodyData;
    }

    return rowData;
}