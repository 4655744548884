import {FleetDataResponse} from "../types/FleetDataResponse";
import {dataRequest, postRequest} from "./DataRequest";
import mockData from "./mockFleetTableData.json"
import mockUserDetails from "./mockUserDetails.json"
import {FleetDataItem} from "../types/FleetDataItem";
import {ShipData} from "../types/ShipData";
import {UserDetails} from "../types/UserDetails";
import { getCurrentSessionIdToken } from "../queries/AuthToken";
import axios from "axios";
import { AiGradeInfo } from "@idwal/idwal-react-components";
import { debounce } from "lodash";
import { InternalGradeData } from "../types/InternalGradeData";
import { addCustomColumnData, addSpecialColumnData } from "../utils/UserHelper";

export const getFleetData = async (): Promise<FleetDataResponse> => {
    const request: FleetDataResponse = await dataRequest("/Fleet");
    let items : FleetDataItem[] = [];

    if (request.userDetails?.fleet) {
        const { fleet, groupDetails } = request.userDetails;

        if (groupDetails) {
            const fleetParsed = JSON.parse(fleet);

            Object.values(fleetParsed).forEach(ship => {
                const shipData : ShipData = ship as ShipData;
                shipData.riskLevel = 0;
                shipData.notes = "";
    
                addSpecialColumnData(groupDetails, shipData);

                const item: FleetDataItem = {
                    inspection: {},
                    vessel: shipData,
                    trending: null
                }
    
                addCustomColumnData(groupDetails, item, shipData);
                items.push(item);
            });
        }
    }

    return Promise.resolve({
        fleetDataItems: items,
        userDetails: request.userDetails as UserDetails,
    });
}

export const getMockFleetData = async (): Promise<FleetDataResponse> => {
    return Promise.resolve({
        fleetDataItems: mockData,
        userDetails: mockUserDetails as any,

    });
}

const debouncedUpdateFleetAppState = debounce((appState) => postRequest("/Users/UpdateUser", appState), 1000);

export const updateFleetAppState = (appState: any) => {
    debouncedUpdateFleetAppState(appState);
}

export const getFleetToken = async () => {
    const response = await dataRequest("/Fleet/v2/token");
    return response.token;
}

const setAiGradeData = (aiGradeData: AiGradeInfo) => {
    if (aiGradeData?.predictedGradeMin && aiGradeData.predictedGradeMax && aiGradeData.predictedGrade) {
        aiGradeData.predictedGrade = Math.round(aiGradeData.predictedGrade);
        aiGradeData.predictedGradeMin = Math.round(aiGradeData.predictedGradeMin);
        aiGradeData.predictedGradeMax = Math.round(aiGradeData.predictedGradeMax);
    }

    if (aiGradeData?.evolutionGradeMin && aiGradeData.evolutionGradeMax) {
        aiGradeData.evolutionGradeMin = Math.round(aiGradeData.evolutionGradeMin);
        aiGradeData.evolutionGradeMax = Math.round(aiGradeData.evolutionGradeMax);
    }
}

export const getInspectionAndGradeData = async (userToken: string) : Promise<any> => {
    const baseUrl: string = process.env.REACT_APP_IDWAL_API_ENDPOINT ?? "";
    const url = baseUrl + "/Fleet/v2/Inspection";
    
    const token = await getCurrentSessionIdToken();

    const data = JSON.stringify({ token: userToken });
    
    const response = await axios.post<any>(url, data, {
        headers: {
            "Content-Type": "application/json",
            Authorization: 'Bearer ' + token
        }
    });

    return response.data;
}

export const getInternalGradeData = async (imos: string) : Promise<InternalGradeData[]> => {
    const baseUrl: string = process.env.REACT_APP_IDWAL_API_ENDPOINT ?? "";
    const url = baseUrl + `/Grades?imos=${imos}`;

    const token = await getCurrentSessionIdToken();

    return axios.get<any>(url, {
        headers: {
            "Content-Type": "application/json",
            Authorization: 'Bearer ' + token
        }
    }).then(result => {
        return result.data.data;
    });
}

export const getFleetStats = async () => {
    return await dataRequest("/Fleet/stats");
}