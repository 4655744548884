import { AppThunk } from "../store";
import { fetchDefects } from "./defectThunks";
import {
    fetchFleetGraph,
    fetchSubgradeBackingData,
    fetchSubgradeData
} from "./fleetGraphThunks";
import {
    fetchInspectionAndGradeData,
    setupFleetData,
    setupFleetStatsData
} from "./fleetThunks";
import { fetchFleetData } from "./listThunks";
import {
    fetchPreviousRecipients,
    fetchSharedDefects
} from "./sharedDefectsThunks";

export const fetchOrder: Record<string, AppThunk[][]> = {
    "": [
        [fetchFleetData()],
        [fetchInspectionAndGradeData()],
        [
            fetchDefects(),
            fetchSubgradeData(),
            fetchSubgradeBackingData()
        ],
        [setupFleetData(), fetchPreviousRecipients(), fetchFleetGraph(), setupFleetStatsData()]
    ],
    grade: [
        [fetchFleetData()],
        [fetchInspectionAndGradeData()],
        [fetchFleetGraph()],
        [
            fetchDefects(),
            fetchSubgradeData(),
            fetchSubgradeBackingData()
        ],
        [setupFleetData(), fetchPreviousRecipients(), setupFleetStatsData()]
    ],
    subgrade: [
        [fetchFleetData()],
        [fetchInspectionAndGradeData()],
        [fetchSubgradeData(), fetchSubgradeBackingData()],
        [fetchDefects()],
        [setupFleetData(), fetchFleetGraph(), fetchPreviousRecipients(), setupFleetStatsData()]
    ],
    defects: [
        [fetchFleetData()],
        [fetchInspectionAndGradeData()],
        [fetchDefects()],
        [
            fetchSubgradeData(),
            fetchSubgradeBackingData()
        ],
        [setupFleetData(), fetchPreviousRecipients(), fetchFleetGraph(), setupFleetStatsData()]
    ],
    techManager: [[fetchSharedDefects()]]
};

export const fetchOrderByList: Record<string, AppThunk[][]> = {
    "": [
        [fetchFleetData()],
        [fetchInspectionAndGradeData()],
        [
            fetchDefects(),
            fetchSubgradeData(),
            fetchSubgradeBackingData()
        ],
        [
            setupFleetData(),
            fetchFleetGraph(),
            setupFleetStatsData()
        ]
    ],
    grade: [
        [fetchFleetData()],
        [fetchInspectionAndGradeData()],
        [fetchFleetGraph()],
        [
            fetchDefects(),
            fetchSubgradeData(),
            fetchSubgradeBackingData()
        ],
        [
            setupFleetData(),
            fetchPreviousRecipients(),
            setupFleetStatsData()
        ]
    ],
    subgrade: [
        [fetchFleetData()],
        [fetchInspectionAndGradeData()],
        [
            fetchDefects(),
            fetchSubgradeData(),
            fetchSubgradeBackingData()
        ],
        [
            setupFleetData(),
            fetchFleetGraph(),
            fetchPreviousRecipients(),
            setupFleetStatsData()
        ]
    ],
    defects: [
        [fetchFleetData()],
        [fetchInspectionAndGradeData()],
        [fetchDefects()],
        [
            setupFleetData(),
            fetchSubgradeData(),
            fetchSubgradeBackingData(),
            fetchPreviousRecipients()
        ],
        [
            fetchFleetGraph(),
            setupFleetStatsData()
        ]
    ]
};
