import { useLocation } from "react-router-dom";
import { GlobalFilter } from "./GlobalFilter";
import { useCapabilities } from "../../hooks/useCapabilities";
import { Capability } from "../../types/Capability";
import { displayByCapabilities } from "../../utils/UserHelper";
import { useMemo } from "react";

const hiddenPaths = ["/rectification", "/rectifications", "/screening"];

export const FleetFilter = () => {
    const { capabilities } = useCapabilities([
        Capability.CAP_FLEETAPP_VIEW_DEFECTS,
        Capability.CAP_FLEETAPP_VIEW_FLEETS,
        Capability.CAP_FLEETAPP_VIEW_GRADES,
        Capability.CAP_FLEETAPP_VIEW_SUBGRADES,
        Capability.CAP_FLEETAPP_VIEW_RECTIFICATIONS,
        Capability.CAP_FLEETAPP_VIEW_PSC,
        Capability.CAP_FLEETAPP_VIEW_SCREENING
    ]);

    const { pathname } = useLocation();

    const isHiddenPath = useMemo(
        () => hiddenPaths.some((path) => pathname.endsWith(path)),
        [pathname]
    );

    const displayByCapability = useMemo(
        () => displayByCapabilities(capabilities, pathname),
        [capabilities, pathname]
    );

    if (isHiddenPath || pathname === "/" || !displayByCapability) {
        return null;
    }

    return <GlobalFilter />;
};
