import { Capability } from "../../types/Capability";
import { selectUserHasCapability } from "../selectors/userSelectors";
import { AppThunk } from "../store";
import { fetchInsightsData } from "./defectInsightsThunks";
import { fetchPSCDeficiencies, fetchPSCInspections, setupPSCVesselData } from "./pscThunks";

export const fetchTokenData = (): AppThunk => async (dispatch, getState) => {
    try {
        const state = getState();
        const hasPSCCapability = selectUserHasCapability(
            state,
            Capability.CAP_FLEETAPP_VIEW_PSC
        );
        
        if (hasPSCCapability) {
            const pscPromoises = [
                dispatch(fetchPSCInspections()),
                dispatch(fetchPSCDeficiencies())
            ];
     
            await Promise.all(pscPromoises);
     
            dispatch(setupPSCVesselData());
        }

        const hasDefectsCapability = selectUserHasCapability(
            state,
            Capability.CAP_FLEETAPP_VIEW_DEFECTS
        );

        if (hasDefectsCapability) {
            dispatch(fetchInsightsData());
        }
     } catch (e) {
        console.error(e);
    }
};


