import {FleetDataItem} from "../../types/FleetDataItem";
import {StatTile} from "../../types/StatTile";
import { STAT_TILE_DUE_INSPECTION  } from "../Constants";


export class InspectionsDueIn30DaysTile implements StatTile {

    fleetInspections : FleetDataItem[]

    constructor(fleetInspections: FleetDataItem[]) {
        this.fleetInspections = fleetInspections;
    }

    getTileData() {
        const vesselsWithInspectionDueIn30Days = this.fleetInspections.filter((entry) => {
            if (!entry.scheduledDate) {
                return false;
            }

            const today = new Date();
            today.setHours(0,0,0,0)
            const thirtyDaysFromNow = new Date();
            thirtyDaysFromNow.setDate(today.getDate() + 30);

            const scheduledDate = new Date(entry.scheduledDate);

            return scheduledDate >= today && scheduledDate <= thirtyDaysFromNow && (!entry.inspection || entry.inspection.isScheduled === false);
        });


        if (vesselsWithInspectionDueIn30Days && vesselsWithInspectionDueIn30Days.length > 0) {

            const dropDownData = vesselsWithInspectionDueIn30Days.map((inspection) => {
                return `${inspection.vessel.vesselName} ${inspection.vessel.imo}`
            });

            const vesselsWithInspectionDueIn30DaysNames = vesselsWithInspectionDueIn30Days.map((entry) => entry.vessel.vesselName).join(", ");
            return {
                data: vesselsWithInspectionDueIn30Days,
                tileKey: STAT_TILE_DUE_INSPECTION,
                statistic: "x" + vesselsWithInspectionDueIn30Days.length,
                details: vesselsWithInspectionDueIn30DaysNames,
                icon: "inspections-due-icon",
                additionalData : dropDownData
            };
        }

        return null;
    }
}