import { FleetDataItem } from "../types/FleetDataItem";
import { ID_PLATFORM_INSPECTION_STATUS_MAPPING } from "@idwal/idwal-utilities/lib/types/AsimsStatusMapping";
import { getTranslationObject } from "../services/Translation";
import { getVesselGrade, GradeType } from "@idwal/idwal-react-components";
import { calculateDaysSince, convertInspectionDates, rankData, setGlobalFilterFields } from "./FleetHelper";
import { InternalGradeData } from "../types/InternalGradeData";
import { INSPECTION_STATUS_UPCOMING } from "./Constants";

function handleUninspectedVesselDueDate(fleetDataItem: FleetDataItem) {
    if (!fleetDataItem.inspection?.inspectionDue) {
        // This handles the scenario where a vessel has never been inspected, but a user defined scheduled date is defined in fleet config for the vessel.
        // Column in the table looks at one field. This is the simplest way to get the data into the correct format for the table.
        if (fleetDataItem.scheduledDate) {
            fleetDataItem.inspection = {};
            fleetDataItem.inspection.inspectionDue = fleetDataItem.scheduledDate;
            fleetDataItem.inspectionDateFilterField = fleetDataItem.scheduledDate;
        }
    }
}

export function addCustomFieldFleetFiltersToData (data: Array<FleetDataItem>) {
    data.forEach(x => {
        x.nameFilterField = x.vessel.vesselName + " " + x.vessel.imo;

        if (x.vesselGrade) {
            if (x.vesselGrade.gradeType === GradeType.IDWAL) {
                x.gradeFilterField = x.vesselGrade.grade;
            } else if (x.vesselGrade.gradeMin && x.vesselGrade.gradeMax) {
                x.gradeFilterField = (x.vesselGrade.gradeMin + x.vesselGrade.gradeMax) / 2;
            } else if (x.vesselGrade.grade) {
                x.gradeFilterField = x.vesselGrade.grade;
            }
        }

        handleUninspectedVesselDueDate(x);

        if (x.inspection.isScheduled) {
            if (x.inspection.dateTo) {
                x.inspectionDateFilterField = new Date(x.inspection.dateTo).toLocaleDateString("en-GB") + " " + new Date(x.inspection.dateFrom!).toLocaleDateString("en-GB");
            } else {
                x.inspectionDateFilterField = new Date(x.inspection.dateFrom!).toLocaleDateString("en-GB");
            }
        } else if (x.inspection.inspectionDue) {
            const inspectionDueDate = x.inspection.inspectionDue;
            x.inspectionDateFilterField = inspectionDueDate;
        }

        const daysSince =  x.inspection.daysSince;

        if (daysSince !== null && daysSince !== undefined && daysSince >= 0) {
            const daysSinceInspectionDate = new Date();
            daysSinceInspectionDate.setDate(daysSinceInspectionDate.getDate() - daysSince);
            x.dateSinceFilterField = daysSinceInspectionDate.toLocaleDateString("en-GB");
        }

        if (x.vessel?.specialSurveyDate) {
            x.vessel.specialSurveyDate = new Date(x.vessel.specialSurveyDate);
            x.specialSurveyDateFilterField = x.vessel?.specialSurveyDate.toLocaleDateString("en-GB");
        }

        x.vesselLatestInspectionStatus = 0;
        if (x.inspection?.isScheduled) {
            x.vesselLatestInspectionStatus = x.inspection.scheduledInspectionStatus
        } else {
            if (x.inspection?.hasUpcoming) {
                x.vesselLatestInspectionStatus = INSPECTION_STATUS_UPCOMING
            } else {
                x.vesselLatestInspectionStatus = x.inspection?.status;
            }
        }
    });

    return data;
}

export function addInspectionDataToTableData(fleetDataItem: FleetDataItem, inspectionAndGradeResponse: FleetDataItem[], internalGrades: InternalGradeData[] | undefined, aiGradingActive: boolean) {
    const key = fleetDataItem.vessel.imo;

    let fleetDataFromApi = inspectionAndGradeResponse.find(item => item.vessel.imo === key);
    if (!fleetDataFromApi) {
        fleetDataFromApi = {
            vessel: fleetDataItem.vessel,
            inspection: {}
        }
    }

    if (fleetDataFromApi.inspection && Object.keys(fleetDataFromApi.inspection).length != 0) {
        fleetDataItem.inspection = fleetDataFromApi.inspection;
    }

    if (!fleetDataItem.scheduledDate && fleetDataFromApi.scheduledDate) {
        fleetDataItem.scheduledDate = fleetDataFromApi.scheduledDate;
    }
    fleetDataItem.rank = fleetDataFromApi.rank;

    if(fleetDataFromApi.scoreTrends && fleetDataFromApi.inspection.idwalGrade != null) {
        fleetDataItem.trending = fleetDataFromApi.scoreTrends.idwalScoreTrend;
        fleetDataItem.trendingLastInspected = fleetDataFromApi.scoreTrends.lastInspected;
    }

    fleetDataItem.aiGrades = fleetDataFromApi.aiGrades ?? undefined;

    const internalGradeData = internalGrades?.find((item: any) => item.imo === key);

    const minMax = internalGradeData?.grade_display.split("-");

    fleetDataItem.aiGrades = {
        ...fleetDataItem.aiGrades,
        evolutionGrade: internalGradeData?.grade,
        evolutionGradeMin: minMax?.length === 2 ? Number(minMax[0]) : undefined,
        evolutionGradeMax: minMax?.length === 2 ? Number(minMax[1]) : undefined,
        predictedGrade: internalGradeData?.grade,
        predictedGradeMin: minMax?.length === 2 ? Number(minMax[0]) : undefined,
        predictedGradeMax: minMax?.length === 2 ? Number(minMax[1]) : undefined,
    }

    fleetDataItem.vesselGrade = getVesselGrade(aiGradingActive, fleetDataItem.inspection?.idwalGrade ? fleetDataItem.inspection?.idwalGrade.toString() : undefined, fleetDataItem.inspection?.status?.toString(), fleetDataItem.aiGrades);

    fleetDataItem.scoreHistory = fleetDataFromApi.scoreHistory;

    if (!fleetDataItem.vesselGrade) {
        fleetDataItem.gradeToDisplay = undefined;
        fleetDataItem.gradeFilterField = undefined;
        return;
    }

    switch (fleetDataItem.vesselGrade.gradeType) {
        case GradeType.IDWAL:
            fleetDataItem.gradeToDisplay = fleetDataItem.vesselGrade.grade?.toString();
            break;
        case GradeType.PREDICTED:
        case GradeType.EVOLUTION:
            if (fleetDataItem.vesselGrade.gradeMin && fleetDataItem.vesselGrade.gradeMax) {
                fleetDataItem.gradeToDisplay = `${fleetDataItem.vesselGrade.gradeMin} - ${fleetDataItem.vesselGrade.gradeMax}`;
            } else {
                fleetDataItem.gradeToDisplay = fleetDataItem.vesselGrade.grade?.toString();
            }
            break;
    }

    fleetDataItem.aiGrades = {
        ...fleetDataItem.aiGrades,
        aiGradeFilterField:fleetDataItem.vesselGrade.gradeFilterField
    };
};

export function mutateFleetDataWithInspections(
    fleetDataItems: FleetDataItem[],
    inspectionAndGradeResponse: FleetDataItem[],
    internalGrades: InternalGradeData[] | undefined,
    aiGradingActive: boolean
) {
    convertInspectionDates(inspectionAndGradeResponse);
    calculateDaysSince(inspectionAndGradeResponse);

    inspectionAndGradeResponse = rankData(inspectionAndGradeResponse, aiGradingActive);

    fleetDataItems.forEach((fleetDataItem: FleetDataItem) => {
        addInspectionDataToTableData(fleetDataItem, inspectionAndGradeResponse, internalGrades, aiGradingActive);
    });

    setGlobalFilterFields(fleetDataItems);
}

export function getFriendlyInspectionStatusText(inspectionStatus: number) {
    const t = getTranslationObject();
    let statusGroup;
    
    if (inspectionStatus !== null && inspectionStatus !== undefined && ID_PLATFORM_INSPECTION_STATUS_MAPPING[inspectionStatus]?.translationKey) {
        statusGroup = ID_PLATFORM_INSPECTION_STATUS_MAPPING[inspectionStatus].translationKey;
    }

    return statusGroup? t(`inspectionStatuses.${statusGroup}`) : t(`inspectionStatuses.neverInspected`);
}
