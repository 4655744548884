import { useAuthenticator } from "@aws-amplify/ui-react";
import { ProgressSpinner } from "primereact/progressspinner";
import { useEffect, useState, ReactNode, useCallback } from "react";
import { AUTH_URL_PROTOCOL } from "../../cognito/authConfig";
import Unauthorised from "../../pages/Unauthorised";
import { getUserInfo } from "../../services/UserService";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { useAppSelector } from "../../hooks/storeHooks";
import { selectHasFleetAccess } from "../../store/selectors/userSelectors";
import { persistor } from "../../store/store";

interface SecuredRouteProps {
    children: ReactNode;
}

export const SecuredRoute = ({ children }: SecuredRouteProps) => { 
    const { authStatus, user } = useAuthenticator();
    const [checkingAuth, setCheckingAuth] = useState(true);
    const hasFleetAccess = useAppSelector(selectHasFleetAccess);
    const ldClient = useLDClient();
    
    const redirectToSSO = useCallback(() => {
        persistor.purge();
        window.location.assign(
            `${AUTH_URL_PROTOCOL}://${process.env.REACT_APP_AUTH_URL}?redirect=${window.location.href}`
        );
    }, []);

    useEffect(() => {
        if (authStatus === "configuring") return;
        if (authStatus === "unauthenticated") {
            redirectToSSO();
            return;
        }
        if (!user) return;

        // Validate authenticated user
        const validateUser = async () => {
            try {
                const userInfo = await getUserInfo(user.username);
                
                if (!userInfo?.acknowledgement) {
                    if (process.env.NODE_ENV === 'development') {
                        console.warn("User has not acknowledged terms");
                    }
                    redirectToSSO();
                    return;
                }

                if (ldClient) {
                    ldClient.identify({
                        key: user.username,
                        email: user.attributes?.email
                    });
                }
            } catch (error) {
                if (process.env.NODE_ENV === 'development') {
                    console.error("Error validating user:", error);
                }
                redirectToSSO();
            } finally {
                setCheckingAuth(false);
            }
        };

        validateUser();
    }, [authStatus, user, ldClient, redirectToSSO]);
    
    // Show loading spinner while determining auth status
    const isLoading = hasFleetAccess === null || authStatus === "configuring" || checkingAuth;
    if (isLoading) {
        return (
            <div className="flex h-screen justify-center items-center">
                <ProgressSpinner />
            </div>
        );
    }

    // Show unauthorized page if user doesn't have fleet access
    if (hasFleetAccess === false && !checkingAuth && authStatus === "authenticated") {
        return <Unauthorised /> ;
    }

    // User is authenticated and has access
    return <>{children}</>;
};
