import { Route, Routes } from "react-router-dom";
import { useCapabilities } from "../../hooks/useCapabilities";
import { Capability } from "../../types/Capability";
import { SecuredRoute } from "../Authentication/SecuredRoute";
import Fleets from "../../pages/Fleets";
import Defects from "../../pages/Defects";
import DefectRectification from "../../pages/DefectRectification";
import Grade from "../../pages/Grade";
import SubGradesPage from "../../pages/SubGrades";
import SharedDefects from "../../pages/SharedDefects";
import PortStateControl from "../../pages/PortStateControl";
import {
    DEFECTS,
    GRADE,
    PSC,
    RECTIFICATIONS,
    SUBGRADES,
    SCREENING
} from "../../types/RouteNames";
import { MarketingPage } from "@idwal/idwal-react-components";
import { sendGAEvent } from "../../AnalyticsService";
import {
    DEFECTS_LEARN_MORE_CLICK,
    DEFECTS_REQUEST_DEMO_CLICK,
    GRADES_LEARN_MORE_CLICK,
    GRADES_REQUEST_DEMO_CLICK,
    MARKETING_PAGE_CATEGORY,
    MY_FLEET_LEARN_MORE_CLICK,
    MY_FLEET_REQUEST_DEMO_CLICK,
    PSC_LEARN_MORE_CLICK,
    PSC_REQUEST_DEMO_CLICK,
    SCREENING_LEARN_MORE_CLICK,
    SCREENING_REQUEST_DEMO_CLICK,
    SUBGRADES_LEARN_MORE_CLICK,
    SUBGRADES_REQUEST_DEMO_CLICK
} from "../../constants/analytics";
import { MarketingPageProps } from "@idwal/idwal-react-components/dist/MarketingPage/MarketingPage";
import { useAuthenticator } from "@aws-amplify/ui-react";
import Screening from "../../pages/Screening";
import { useCallback, useMemo } from "react";

export const MainRoutes = () => {
    const { capabilities } = useCapabilities([
        Capability.CAP_FLEETAPP_VIEW_DEFECTS,
        Capability.CAP_FLEETAPP_VIEW_FLEETS,
        Capability.CAP_FLEETAPP_VIEW_GRADES,
        Capability.CAP_FLEETAPP_VIEW_SUBGRADES,
        Capability.CAP_FLEETAPP_VIEW_RECTIFICATIONS,
        Capability.CAP_FLEETAPP_VIEW_PSC,
        Capability.CAP_FLEETAPP_VIEW_SCREENING
    ]);

    const { user } = useAuthenticator((context) => [context.user]);

    const getMarketingPage = useCallback(
        (
            type: MarketingPageProps["type"],
            learnMoreAction: string,
            demoAction: string,
            showLogo = true
        ) => {
            const learnMoreCallback = () =>
                sendGAEvent(MARKETING_PAGE_CATEGORY, learnMoreAction);
            const requestDemoCallback = () =>
                sendGAEvent(MARKETING_PAGE_CATEGORY, demoAction);

            return (
                <div className="h-full flex flex-column align-items-center justify-content-center">
                    <div id={"marketing-container"}>
                        <MarketingPage
                            type={type}
                            learnMoreCallback={learnMoreCallback}
                            requestDemoCallback={requestDemoCallback}
                            showLogo={showLogo}
                        />
                    </div>
                </div>
            );
        },
        []
    );

    const mainRoutes = useMemo(() => {
        const routes = [];

        if (Object.keys(capabilities).length) {
            // Techmanagers only have this route
            if (capabilities[Capability.CAP_FLEETAPP_VIEW_RECTIFICATIONS]) {
                routes.push(
                    <Route key="rectifications" path="/">
                        <Route path="/" element={<SharedDefects />} />
                        <Route
                            path={`/${RECTIFICATIONS}`}
                            element={<SharedDefects />}
                        />
                        <Route
                            path=":defectId/rectification"
                            element={<DefectRectification sharedDefect />}
                        />
                        <Route
                            path={`/${RECTIFICATIONS}/:defectId/rectification`}
                            element={<DefectRectification sharedDefect />}
                        />
                    </Route>
                );
            } else {
                const fleets = capabilities[
                    Capability.CAP_FLEETAPP_VIEW_FLEETS
                ] ? (
                    <Fleets />
                ) : (
                    getMarketingPage(
                        "myFleet",
                        MY_FLEET_LEARN_MORE_CLICK,
                        MY_FLEET_REQUEST_DEMO_CLICK
                    )
                );

                routes.push(
                    <Route key="fleet" path="/">
                        <Route path="/" element={fleets} />
                    </Route>
                );

                const grades = capabilities[
                    Capability.CAP_FLEETAPP_VIEW_GRADES
                ] ? (
                    <Grade />
                ) : (
                    getMarketingPage(
                        "grade",
                        GRADES_LEARN_MORE_CLICK,
                        GRADES_REQUEST_DEMO_CLICK
                    )
                );

                routes.push(
                    <Route key={GRADE} path={`/${GRADE}`}>
                        <Route path={`/${GRADE}`} element={grades} />
                    </Route>
                );

                const subgrades = capabilities[
                    Capability.CAP_FLEETAPP_VIEW_SUBGRADES
                ] ? (
                    <SubGradesPage />
                ) : (
                    getMarketingPage(
                        "subgrade",
                        SUBGRADES_LEARN_MORE_CLICK,
                        SUBGRADES_REQUEST_DEMO_CLICK
                    )
                );

                routes.push(
                    <Route key={SUBGRADES} path={`/${SUBGRADES}`}>
                        <Route path={`/${SUBGRADES}`} element={subgrades} />
                    </Route>
                );

                const defects = capabilities[
                    Capability.CAP_FLEETAPP_VIEW_DEFECTS
                ] ? (
                    <Defects />
                ) : (
                    getMarketingPage(
                        "defects",
                        DEFECTS_LEARN_MORE_CLICK,
                        DEFECTS_REQUEST_DEMO_CLICK
                    )
                );

                const rectification = capabilities[
                    Capability.CAP_FLEETAPP_VIEW_DEFECTS
                ] ? (
                    <DefectRectification />
                ) : null;

                routes.push(
                    <Route key="defects" path={`/${DEFECTS}`}>
                        <Route path={`/${DEFECTS}`} element={defects} />
                        <Route
                            path=":defectId/rectification"
                            element={rectification}
                        />
                    </Route>
                );

                const psc = capabilities[Capability.CAP_FLEETAPP_VIEW_PSC] ? (
                    <PortStateControl />
                ) : (
                    getMarketingPage(
                        "psc",
                        PSC_LEARN_MORE_CLICK,
                        PSC_REQUEST_DEMO_CLICK
                    )
                );

                routes.push(
                    <Route key="psc" path="/">
                        <Route path={`/${PSC}`} element={psc} />
                    </Route>
                );

                const screening = capabilities[
                    Capability.CAP_FLEETAPP_VIEW_SCREENING
                ] ? (
                    <Screening />
                ) : (
                    getMarketingPage(
                        "screening",
                        SCREENING_LEARN_MORE_CLICK,
                        SCREENING_REQUEST_DEMO_CLICK
                    )
                );

                routes.push(
                    <Route key="screening" path="/">
                        <Route path={`/${SCREENING}`} element={screening} />
                    </Route>
                );
            }
        }

        return routes;
    }, [user, capabilities]);

    return (
        <SecuredRoute>
            <Routes>{mainRoutes}</Routes>
        </SecuredRoute>
    );
};
