import { getCurrentSessionIdToken } from "../queries/AuthToken"
import { CognitoUser } from '@aws-amplify/auth'
import axios from 'axios';
import { errorToast } from "@idwal/idwal-react-components";
import { UserCapabilitiesApiResponse } from "../types/UserCapabilitiesApiResponse";
import { getTranslationObject } from "./Translation";
import {Capability} from "../types/Capability";
import { dataRequest } from "./DataRequest";

const t = getTranslationObject();

const getHeader = async () => {
    const token = await getCurrentSessionIdToken();

    return {
        "Content-Type": "application/json",
        "Authorization": 'Bearer ' + token
    }
}

export const isIdUser = (capabilities: string[]) => capabilities.some(capability => capability == Capability.CAP_FLEETAPP_VIEW_FLEETS || Capability.CAP_FLEETAPP_VIEW_PSC || Capability.CAP_FLEETAPP_VIEW_SUBGRADES || Capability.CAP_FLEETAPP_VIEW_DEFECTS || Capability.CAP_FLEETAPP_VIEW_GRADES );

const getUserGroups = (user: CognitoUser) => {
    if (user) {
        const signInSession = user.getSignInUserSession();

        if (signInSession) {
            const accessToken = signInSession.getAccessToken();

            if (accessToken) {
                return accessToken.payload["cognito:groups"];
            }

        }
    }
    return [];
}

export const userIsTechnicalManager = (user: CognitoUser): boolean => {
    const userGroups = getUserGroups(user);

    if (!userGroups) {
        return false;
    }

    return userGroups.includes('FleetTechnicalManager');
}

export const getUserCapabilities = async (userId: string | undefined) => {
    if (userId) {     
        const url = process.env.REACT_APP_IDWAL_API_ENDPOINT + `/Users/${userId}/Capabilities?app=FleetApp`;

        const headers = await getHeader();
    
        const data = { userId, app: process.env.REACT_APP_FLEET_APP_GROUP };
    
        try {
            const response = await axios.get<UserCapabilitiesApiResponse>(url, { headers, data });

            if (response.data.data?.length) {
                return response.data.data;
            } else {
                return [];
            }
        } catch (error) {
            errorToast(t("userCapabilities.getCapabilitiesError"));
            return [];
        }
    }
    
    return [];
}

export const getUserInfo = async (userId: string | undefined) => {
  if (userId) {
    const apiBaseUrl = process.env.REACT_APP_IDWAL_API_ENDPOINT;
    const jwtToken = await getCurrentSessionIdToken();

    const api = axios.create({
      baseURL: apiBaseUrl,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
    });

    try {
      const apiResponse = await api.get(`/Users/${userId}/Info`);

      const userInfo = apiResponse.data.data;
      return userInfo;
    } catch (error) {
      return null;
    }
  }

  return null;
};

export const getUserDetails = async (group: string) => {
    return await dataRequest(`/Fleet/v2/userdata/${group}`);
}

export const getListData = async (group: string, id: string) => {
    return await dataRequest(`/Fleet/v2/groups/${group}/lists/${id}`);
}

export const getGroupListsData = async (group: string) => {
    return await dataRequest(`/Fleet/v2/groups/${group}/lists`);
}