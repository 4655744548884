import axios from "axios";
import { getCurrentSessionIdToken } from "../queries/AuthToken";

const fields = "imo,vessel_name,vessel_type,technical_manager,classification_society,flag_state,deadweight,year_of_build,ship_age,build_country,teu"


export const getShipDetails = async (imos: number[]) => {
    try {
        const url = `${process.env.REACT_APP_IDWAL_API_ENDPOINT}/ships/search/v2?imo=${imos.join(",")}&fields=${fields}`;

        const token = await getCurrentSessionIdToken();
        
        const headers = {
            "Content-Type": "application/json",
            "Authorization": 'Bearer ' + token,
        }

        const response = await axios.get(url, { headers });

        return response.data.results;
    } catch (e) {
        console.log(e);

        return[];
    }
}