import axios from "axios";
import { getCurrentSessionIdToken } from "../queries/AuthToken";
import { List } from "../types/List";

const baseUrl = `${process.env.REACT_APP_IDWAL_API_ENDPOINT}/Fleet/v2/groups`;

export const updateGroup = async (
    group: string,
    data: Record<string, unknown>
) => {
    try {
        const url = `${baseUrl}/${group}/data`;

        const token = await getCurrentSessionIdToken();

        const headers = {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token
        };

        const response = await axios.post(url, data, { headers });

        return response.status === 200;
    } catch (e) {
        console.log(e);

        return [];
    }
};

export const updateGroupList = async (group: string, id: string, list: Partial<List>) => {
    try {
        const url = `${process.env.REACT_APP_IDWAL_API_ENDPOINT}/Fleet/v2/groups/${group}/lists/${id}`;

        const token = await getCurrentSessionIdToken();
        
        const headers = {
            "Content-Type": "application/json",
            "Authorization": 'Bearer ' + token,
        }

        const data = JSON.stringify(list);

        const response = await axios.put(url, data, { headers });

        return response.status === 200;
    } catch (e) {
        console.log(e);
    }
}
