import { getFleetGraphData } from "../../services/FleetGraphDataService";
import {
    getBackgroundSubgradeData,
    getSubgradeData
} from "../../services/SubGradeDataService";
import { getTranslationObject } from "../../services/Translation";
import { Capability } from "../../types/Capability";
import { getBackgroundFilterIndex } from "../../utils/advancedFilter/helpers";
import { getDateRangeFromAdvancedFilterInspectionDate } from "../../utils/AdvancedFilterHelper";
import { transformSubgradesInspectionData } from "../../utils/TransformSubgrades";
import {
    selectAppliedFilters,
    selectFitlerState
} from "../selectors/advancedFiltersSelectors";
import { selectGlobalFilters } from "../selectors/filtersSelectors";
import { selectInspectionAndGradeData } from "../selectors/fleetSelectors";
import { selectSelectedList, selectUserHasCapability } from "../selectors/userSelectors";
import {
    setData,
    setError,
    setLoading,
    setSubgradeBackingData,
    setSubgradeBackingError,
    setSubgradeBackingLoading,
    setSubgradeData,
    setSubgradeError,
    setSubgradeLoading
} from "../slices/fleetGraphSlice";
import { AppThunk } from "../store";

export const fetchFleetGraph =
    (): AppThunk<Promise<boolean>> => async (dispatch, getState) => {
        const state = getState();
        const hasCapability = selectUserHasCapability(
            state,
            Capability.CAP_FLEETAPP_VIEW_GRADES
        );
        
        if (hasCapability) {
            const appliedFilters = selectAppliedFilters(state);
            const filterState = selectFitlerState(state);

            const t = getTranslationObject();

            const backgroundDataIndex = getBackgroundFilterIndex(
                appliedFilters.marketDataToggleSelected
            );
            const inspectionDateRange =
                getDateRangeFromAdvancedFilterInspectionDate(t, appliedFilters);

            dispatch(setLoading(true));
            dispatch(setError(false));

            try {
                const response = await getFleetGraphData(
                    filterState.background[backgroundDataIndex],
                    inspectionDateRange.dateRange
                );

                if (response?.message?.length >= 0) {
                    dispatch(setData(response.message));
                } else {
                    throw Error("no data");
                }
            } catch (e) {
                console.error(e);
                dispatch(setError(true));
            } finally {
                dispatch(setLoading(false));
            }
        }

        return true;
    };

export const fetchSubgradeData =
    (): AppThunk<Promise<boolean>> =>
    async (dispatch, getState) => {
        const state = getState();
        const hasCapability = selectUserHasCapability(
            state,
            Capability.CAP_FLEETAPP_VIEW_SUBGRADES
        );
        
        if (hasCapability) {
            dispatch(setSubgradeLoading(true));
            dispatch(setSubgradeError(false));

            try {
                const list = selectSelectedList(state);

                const vessels = list?.vessels;

                if (vessels?.length) {
                    const response = await getSubgradeData(vessels);

                    if (response) {     
                        const inspectionAndGradeData = selectInspectionAndGradeData(state);
            
                        const transformedSubgrades = transformSubgradesInspectionData(
                            response,
                            inspectionAndGradeData
                        );
                
                        dispatch(setSubgradeData(transformedSubgrades));
                    } else {
                        throw Error("no data");
                    }
                }
            } catch (e) {
                console.error(e);
                dispatch(setSubgradeError(true));
            } finally {
                dispatch(setSubgradeLoading(false));
            }
        }

        return true;
    };

export const fetchSubgradeBackingData =
    (): AppThunk<Promise<boolean>> => async (dispatch, getState) => {
        const state = getState();
        const hasCapability = selectUserHasCapability(
            state,
            Capability.CAP_FLEETAPP_VIEW_SUBGRADES
        );
        
        if (hasCapability) {
            const filterState = selectFitlerState(state);
            const globalFilters = selectGlobalFilters(state);

            const t = getTranslationObject();

            dispatch(setSubgradeBackingLoading(true));
            dispatch(setSubgradeBackingError(false));

            const filterIndex = getBackgroundFilterIndex(
                filterState.marketDataToggleSelected
            );
            const inspectionDates = getDateRangeFromAdvancedFilterInspectionDate(
                t,
                filterState
            );

            try {
                const response = await getBackgroundSubgradeData(
                    filterState.background[filterIndex],
                    globalFilters,
                    inspectionDates.dateRange
                );
                if (response) {
                    dispatch(setSubgradeBackingData(response));
                } else {
                    throw Error("no data");
                }
            } catch (e) {
                console.error(e);
                dispatch(setSubgradeBackingError(true));
            } finally {
                dispatch(setSubgradeBackingLoading(false));
            }
        }

        return true;
    };
