import { configureStore, ThunkAction, Action, combineReducers } from '@reduxjs/toolkit';
import { persistStore } from 'redux-persist';

// Reducer Imports
import advancedFiltersReducer from './slices/advancedFiltersSlice';
import defectReducer from './slices/defectSlice';
import defectInsightsReducer from './slices/defectInsightsSlice';
import filtersReducer from './slices/filtersSlice';
import fleetReducer from './slices/fleetSlice';
import fleetGraphReducer from './slices/fleetGraphSlice';
import notificationsReducer from './slices/notificationsSlice';
import pscReducer from './slices/pscSlice';
import rectificationReducer from './slices/rectificationSlice';
import screeningReducer from './slices/screeningSlice';
import sharedDefectsReducer from './slices/sharedDefectsSlice';
import userReducer from './slices/userSlice';
import vesselViewReducer from './slices/vesselViewSlice';

// Remove the type annotation from combineReducers
const rootReducer = combineReducers({
    advancedFilters: advancedFiltersReducer,
    defect: defectReducer,
    defectInsights: defectInsightsReducer,
    filters: filtersReducer,
    fleet: fleetReducer,
    fleetGraph: fleetGraphReducer,
    notifications: notificationsReducer,
    psc: pscReducer,
    rectification: rectificationReducer,
    screening: screeningReducer,
    sharedDefects: sharedDefectsReducer,
    user: userReducer,
    vesselView: vesselViewReducer,
});

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
          serializableCheck: {
            ignoredActions: ["persist/PERSIST", "persist/REHYDRATE", "persist/PURGE"],
          },
    }),
});

export const persistor = persistStore(store);

// Updated type definitions
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;