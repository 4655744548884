import { GroupDetails } from "../types/GroupDetails";
import { ShipData } from "../types/ShipData";
import { jwtDecode } from "jwt-decode";
import { CUSTOM_COLUMN_PREFIX } from "./Constants";
import { FleetDataItem } from "../types/FleetDataItem";
import { UserLists } from "../types/List";
import { Capability } from "../types/Capability";

export const decodeFleetToken = (token: string) => {
    // Add typing when token structure is decided
    const decoded = jwtDecode(token) as any;

    return decoded.data.lists as UserLists;
};

export const addSpecialColumnData = (
    group: GroupDetails,
    shipData: ShipData
) => {
    if (
        group?.hasOwnProperty("riskLevels") &&
        group["riskLevels"].hasOwnProperty(shipData.imo)
    ) {
        shipData.riskLevel = Number(group["riskLevels"][shipData.imo]);
    }

    if (
        group?.hasOwnProperty("notes") &&
        group["notes"].hasOwnProperty(shipData.imo)
    ) {
        shipData.notes = group["notes"][shipData.imo];
    }

    if (
        group?.hasOwnProperty("specialSurveyDates") &&
        group["specialSurveyDates"].hasOwnProperty(shipData.imo)
    ) {
        shipData.specialSurveyDate = group["specialSurveyDates"][shipData.imo];
    }

    // remove once multi-list is out
    if (group.name) {
        shipData.group = group.name;
    }
};

export const addCustomColumnData = (
    group: GroupDetails,
    fleetDataItem: FleetDataItem,
    vessel: ShipData
) =>
    Object.keys(group).forEach((key) => {
        if (key.startsWith(CUSTOM_COLUMN_PREFIX)) {
            if (group[key]) {
                if (group[key][vessel.imo]) {
                    fleetDataItem[key] = group[key][vessel.imo];
                } else {
                    fleetDataItem[key] = "";
                }

                fleetDataItem[`${key}.dataType`] = group[key]["dataType"];
            }
        }
    });


export const addDefaultScheduledDates = (
    scheduledData: Record<string, { scheduled: string | undefined }> | undefined,
    fleetDataItem: FleetDataItem
) => {
    if (scheduledData) {
        const scheduledItem = scheduledData[fleetDataItem.vessel.imo];

        if (scheduledItem?.scheduled) {
            fleetDataItem.scheduledDate = scheduledItem.scheduled;
        }
    }
}

export const displayByCapabilities = (
    capabilities: Record<string, boolean>,
    pathname: string
) => {
    if (capabilities[Capability.CAP_FLEETAPP_VIEW_FLEETS] && pathname == "/") {
        return true;
    }

    if (
        capabilities[Capability.CAP_FLEETAPP_VIEW_GRADES] &&
        pathname == "/grade"
    ) {
        return true;
    }

    if (
        capabilities[Capability.CAP_FLEETAPP_VIEW_SUBGRADES] &&
        pathname == "/subgrades"
    ) {
        return true;
    }

    if (
        capabilities[Capability.CAP_FLEETAPP_VIEW_DEFECTS] &&
        pathname == "/defects"
    ) {
        return true;
    }

    if (capabilities[Capability.CAP_FLEETAPP_VIEW_PSC] && pathname == "/psc") {
        return true;
    }

    if (
        capabilities[Capability.CAP_FLEETAPP_VIEW_SCREENING] &&
        pathname == "/screening"
    ) {
        return true;
    }
};


    