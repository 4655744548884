import { VesselSubgradeData } from "../types/VesselSubgradeResponse";
import { InspectionAndGradeDataResponse } from "../types/InspectionAndGradeDataResponse";


const getCorrespondingInspection = (gradeInspectionId: string, inspectionAndGradeResponse: InspectionAndGradeDataResponse) => {
    return inspectionAndGradeResponse?.fleetDataItems.find(
        (i) =>  i.inspection?.guid !== undefined && i.inspection.guid.toUpperCase() === gradeInspectionId
    );
}

export const transformSubgradesInspectionData = (
    subgrades: {[key: string]: VesselSubgradeData }, 
    inspectionAndGradeResponse: InspectionAndGradeDataResponse
) => {
    return Object.assign(
        {}, 
        ...Object.entries(subgrades)
            .map(
                ([key, subgradeData]) => {
                    const gradeInspectionId = subgradeData.id.toUpperCase();
                    const inspection = getCorrespondingInspection(gradeInspectionId, inspectionAndGradeResponse);

                    if (!inspection) {
                        console.warn("No corresponding inspection found for subgrade ID: ", gradeInspectionId)
                    }
                    
                    return {
                        [key]: { 
                            ...subgradeData, 
                            vesselName: inspection?.vessel.vesselName, 
                            technicalManager: inspection?.vessel.technicalManager
                        }
                    }
                }
            )
        );
}
