import { Sidebar } from "primereact/sidebar";
import "./VesselViewSidebar.css";
import { IdwalGrade, vesselViewInspectionDueBodyTemplate } from "@idwal/idwal-react-components";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import { useTranslation } from "react-i18next";
import { MenuButton } from "../MenuButton/MenuButton";
import { VesselViewAccordion } from "./VesselViewAccordion";
import { InspectionReportCard } from "./InspectionReportCard";
import { Inspection } from "../../types/Inspection";
import { Skeleton } from "primereact/skeleton";
import {getFriendlyInspectionStatusText} from "../../utils/FleetTableDataHelper";

const GEARLESS = "gearless";

export interface VesselViewDetails {
    vesselName: string;
    vesselImageUrl?: string;
    vesselImageLoading: boolean,
    noVesselImageAvailable: boolean,
    vesselType: string;
    technicalManager: string;
    latestInspection?: string | null;
    idwalGrade?: number | null;
    imoNumber: string;
    scoreHistory?: Inspection[];
    particularsData:  Array<[string, string]> | undefined;
    particularsLoading: boolean;
    shipImageUrl?: string;
    nextInspectionDetails: {
        isScheduled?: boolean;
        status?: number | null;
        dateFrom?: Date | null;
        dateTo?: Date | null;
        inspectionDue?: Date | null;
        hasUpcoming?: boolean;
    }
}

export interface VesselViewSidebarProps {
    open: boolean;
    vesselDetails?: VesselViewDetails;
    onClose: () => void;
    onRequestInspection: () => void;
}

export const VesselViewSidebar = ({ 
    open, 
    vesselDetails,
    onClose,
    onRequestInspection
}: VesselViewSidebarProps) => {
    const { t } = useTranslation("locale");

    const menuItems = [
        {
            label: t("menuButton.requestInspection"),
            icon: <img className="pr-2" alt="request_inspection" src="./request-inspection-menu.svg"></img>,
            ["data-testId"]: "vessel-view-request-inspection",
            command: onRequestInspection
        }
    ];

    const renderImage = (vesselDetails?: VesselViewDetails) => {
        if (vesselDetails?.vesselImageLoading) {
           return <Skeleton 
                data-testid={"image-skeleton"}
                className="vesselView__imageSkeleton"
            />
        }

        if (vesselDetails && vesselDetails.noVesselImageAvailable) {
            return <img
                alt={""}
                className="vessel-view__noVesselImage"
                data-testid={"no-image-available"}
                src="/no_image_available.png"
            />
        }

        return <img 
            alt={""}
            src={vesselDetails?.vesselImageUrl} 
            className="vessel-view__image"
            data-testid={"vessel-image"}
        />
    }

    return (
        <div className="card flex justify-content-center">
            <Sidebar
                id={"vessel-view-sidebar"}
                position={"left"}
                visible={open}
                onHide={onClose}
                blockScroll={true}
                content={() => {
                    return (
                        <div id={"vessel-view-container"} className={"grid h-full w-full"}>
                            <div className={"col-5 vessel-view-header"}>
                                <div className={"sm:hidden"}>
                                    <img className="sm:hidden vessel-view__logo" alt="Idwal ID logo" src={"id-logo-solo.svg"} />
                                </div>

                                <div className={"hidden sm:block"}>
                                    <img className="hidden sm:block vessel-view__logo" alt="Idwal ID logo" src={"idwal-id-logo.svg"} />
                                </div>
                            </div>
                            <div
                                id={"vessel-view-right-header"}
                                className={"flex justify-content-end align-items-center col-7 p-0"}
                            >
                                <Button
                                    icon="pi pi-times"
                                    id="vessel-view-close-button"
                                    aria-label="Cancel"
                                    data-testid={"close-ship-view"}
                                    onClick={onClose}
                                />
                            </div>

                            <div id={"vessel-view-left-column"} className={"sm:col-5 sm:overflow-y-scroll col-12 pl-3 sm:my-3"}>
                                <div className="vessel-view__image">
                                    {renderImage(vesselDetails)}
                                </div>
                                <p className="vesselView__vesselName">{vesselDetails?.vesselName}</p>
                                <p className="vesselView__vesselImo">IMO {vesselDetails?.imoNumber}</p>
                                <Divider />
                                <p className="vesselView__labelTitle">{`${t("vesselView.vesselType")}`}</p>
                                <p className="vesselView__labelText">{vesselDetails?.vesselType}</p>
                                <div className="vesselView__labelSeperator" />
                                <p className="vesselView__labelTitle">{`${t("vesselView.technicalManager")}`}</p>
                                <p className="vesselView__labelText">
                                    {vesselDetails?.technicalManager
                                        ? vesselDetails.technicalManager
                                        : t("vesselView.unknown")}
                                </p>
                                <Divider />
                                <p className="vesselView__labelTitle">{`${t("vesselView.latestInspection")}`}</p>
                                <p className="vesselView__labelText">
                                    {vesselDetails?.latestInspection
                                        ? vesselDetails.latestInspection
                                        : t("vesselView.neverInspected")}
                                </p>
                                <div className="vesselView__labelSeperator" />
                                {
                                    <>
                                        <p className="vesselView__labelTitle">{`${t("vesselView.nextInspection")}`}</p>
                                        {vesselViewInspectionDueBodyTemplate(getFriendlyInspectionStatusText, t("fleetTable.overdue"),  vesselDetails)}
                                    </>
                                }
                                <div className="vesselView__labelSeperator" />
                                <MenuButton 
                                    menu={menuItems} 
                                    secondary
                                />
                            </div>
                            <div
                                id={"vessel-view-right-column"}
                                className={"sm:col-7 sm:overflow-y-scroll h-full col-12"}
                            >
                                <div className={"vesselView__gradeContainer"}>
                                    <IdwalGrade
                                        grade={vesselDetails?.idwalGrade as number | undefined}
                                        size={"small"}
                                        width={181}
                                        height={71}
                                        className={""}
                                    />
                                </div>
                                <VesselViewAccordion title={t("vesselView.accordions.inspectionReports")}>
                                    {vesselDetails?.scoreHistory && vesselDetails?.scoreHistory?.length > 0 ? vesselDetails?.scoreHistory?.map((inspection, index) => {
                                        if (!inspection.inspectionType || !inspection.inspectionNumber || !inspection.id) {
                                            return null;
                                        }

                                        return (
                                            <div id={`report-card-container-${inspection.id}`}>
                                                <InspectionReportCard
                                                    grade={inspection.idwalGrade ?? undefined}
                                                    inspectionType={inspection.inspectionType as string}
                                                    inspectionReference={inspection.inspectionNumber}
                                                    issuedAt={inspection.issuedAt}
                                                    inspectionGuid={inspection.id}
                                                />
                                                {vesselDetails.scoreHistory && index < vesselDetails.scoreHistory.length - 1 &&
                                                    <Divider/>}
                                            </div>
                                        )
                                    }) : <p>{t("vesselView.noInspectionReports")}</p>}
                                </VesselViewAccordion>
                                {
                                    vesselDetails?.particularsLoading ?
                                        <Skeleton id={"vesselView__particularsSkeleton"} className={"mt-4"}></Skeleton>
                                    :
                                        vesselDetails?.particularsData && <VesselViewAccordion title={t("vesselView.accordions.vesselParticulars")}>
                                            <div className={"flex flex-column"}>
                                                {
                                                    vesselDetails.particularsData.map((particularsTuple: [string, string]) => {
                                                        const particularValue = particularsTuple[1];
                                                        const shipParticularKey = particularsTuple[0];

                                                        if (!particularValue) {
                                                            return <></>
                                                        } else {
                                                            return <div key={shipParticularKey + "Container"} className={"flex flex-column pb-3"}>
                                                                <p className={"vesselView__particularsTitle"} data-testid={shipParticularKey} key={shipParticularKey}>{t(`vesselView.particulars.${shipParticularKey}`)}</p>
                                                                <p className={"vesselView__particularsValue"} key={shipParticularKey + "Value"}>
                                                                    {shipParticularKey === GEARLESS ? t(`vesselView.particulars.${particularValue}`) : particularValue}
                                                                </p>
                                                            </div>
                                                        }
                                                    })
                                                }
                                            </div>
                                        </VesselViewAccordion>
                                }
                            </div>
                        </div>
                    );
                }}
            ></Sidebar>
        </div>
    );
};
