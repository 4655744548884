import createCompressor from "redux-persist-transform-compress";

const userKey = (userName: string, key: any) => {
    const storageKey = `${userName}_${key}`;
    return storageKey;
};

export const storeUserSessionStorageItem = (
    userName: string,
    key: string,
    storageItem: any
) => sessionStorage.setItem(
    userKey(userName, key), JSON.stringify(storageItem)
);

export const getUserSessionStorageItem = (
    userName: string,
    key: string,
) => sessionStorage.getItem(
        userKey(userName, key)
);

export const removeUserSessionStorageItem = 
    (userName: string, key: string) => 
        sessionStorage.removeItem(
            userKey(userName, key)
        );

export const compressor = createCompressor();