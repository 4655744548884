import {
    AdvancedFilterSelectionState,
    AdvancedFilterStateType,
    BackgroundFilters,
    FilterType,
    ForegroundFilters,
    MultiSelectFilterState,
    RangeFilterState,
    SingleSelectFilterState
} from "../../types/AdvancedFilters";
import { InspectionAndGradeDataResponse } from "../../types/InspectionAndGradeDataResponse";
import { MarketDataFilteringOptions } from "../../types/MarketDataFilteringOptions";
import { MultiSelectOptions } from "../../types/BuildFilterSectionProps";
import { VesselTypeWithCount } from "../../types/VesselTypeWithCount";
import { SingleSelectItem } from "../../types/FilterDialog";
import { GlobalDateRange } from "../../components/Filter/GlobalDateRange";

export type CheckboxState = {
    name: string,
    value: string,
    checked: boolean
}

export const sectionIsSelected = (sectionName: string, selectedState: string) => 
    sectionName.toUpperCase() === selectedState.toUpperCase();

export const toCheckboxState = (item: MultiSelectOptions, selectedItems: string[]): CheckboxState => ({
    name: item.name,
    value: item.value,
    checked: selectedItems.some((selectedItem) => selectedItem === item.value),
});

export const createSelection = (sectionName: string, allSelected: boolean): CheckboxState => ({
    name: sectionName,
    value: sectionName,
    checked: allSelected,
});

const newSelectionState = (state: Array<string>, newItem: string): Array<string> =>
    state.some((item: string) =>
        item.toLowerCase() === newItem.toLowerCase()
    ) ? state.filter(
        (item) => item.toLowerCase() !== newItem.toLowerCase()
    ) : state.concat(newItem)

export const generateNewMultiSelectState = (
    updateItem: string,
    allSelection: string,
    state: Array<string>,
    options: Array<MultiSelectOptions>
): Array<string> => {
    const allItemsSelected = state.length === options.length;
    const allSelected = updateItem.toLowerCase() === allSelection.toLowerCase();

    if (allSelected && allItemsSelected) {
        return []
    }

    if (allSelected && !allItemsSelected) {
        return options.map(toSelectionValue);
    }

    return newSelectionState(state, updateItem);
};

export const getBackgroundFilterIndex = (
    marketDataSelected: boolean
) => 
    marketDataSelected
        ? 1
        : 0

export const getAdvancedFilterCount = (filterState: ForegroundFilters | BackgroundFilters): number =>
    Object.values(filterState).reduce((total, current) => {
        const filterType = (current as FilterType).type;

        if (filterType === AdvancedFilterStateType.RangeState) {
            return total + (((current as RangeFilterState).changed) ? 1 : 0);
        }

        if (filterType === AdvancedFilterStateType.MultiSelectState) {
            return total + (((current as MultiSelectFilterState).data) ? (current as MultiSelectFilterState).data.length : 0);
        }

        if (filterType === AdvancedFilterStateType.SingleSelect) {
            const defaultSelected = (current as SingleSelectFilterState).data.find(e => e.default);
            const selected = (current as SingleSelectFilterState).data.find(e => e.checked);
            return total + ((defaultSelected?.key !== selected?.key) ? 1 : 0);
        }

        return total;
    }, 0);

export const hasBackgroundFilters = (filterState: [BackgroundFilters, BackgroundFilters], marketDataSelected: boolean) => 
    filterState[getBackgroundFilterIndex(marketDataSelected)].countryOfBuild.data.length > 0
    || filterState[getBackgroundFilterIndex(marketDataSelected)].dwt.changed === true
    || filterState[getBackgroundFilterIndex(marketDataSelected)].teu.changed === true;

export const getDwtRangeText = (t: any, range: [number, number], localeSentence: string = "benchmarkModal.dwtRangeSentence") => {
    const minValue = range[0].toLocaleString(undefined, {maximumFractionDigits: 2})
    const maxValue = range[1].toLocaleString(undefined, {maximumFractionDigits: 2})
    return t(localeSentence, {min: minValue, max: maxValue});
}

export const getFleetDwtRange = (inspectionAndGradeDataResponse: InspectionAndGradeDataResponse, min: number): [number, number] => {
    let dwtMax = 60000;

        dwtMax = inspectionAndGradeDataResponse.fleetDataItems.length > 0
            ? Math.max(...inspectionAndGradeDataResponse.fleetDataItems.map(item => item?.vessel?.dwt || 0))
            : 60000;

    return [min, Math.ceil(dwtMax / 1000) * 1000];
}

export const rangeEqual = (range1: [number, number], range2: [number, number]) => {
    return range1[0] == range2[0] && range1[1] == range2[1];
}

export const getDefaultFleetDwtState = (
    previousState: RangeFilterState,
    inspectionAndGradeData: InspectionAndGradeDataResponse,
    defaultMinValue: number
): RangeFilterState  => (
    {
        ...previousState,
        data: getFleetDwtRange(inspectionAndGradeData, defaultMinValue),
        limit: getFleetDwtRange(inspectionAndGradeData, defaultMinValue),
        changed: false,
    }
);

export const getDefaultMarketDwtState = (
    previousState: RangeFilterState,
    inspectionAndGradeData: InspectionAndGradeDataResponse,
    defaultMinValue: number,
    defaultMaxValue: number,
    marketDataFilteringOptions?: MarketDataFilteringOptions,
): RangeFilterState => (
    {
        ...previousState,
        data: [defaultMinValue, marketDataFilteringOptions?.dwt.max ?? defaultMaxValue],
        limit: [
            defaultMinValue,
            marketDataFilteringOptions?.dwt.max ?? defaultMaxValue
        ],
        changed: false,
    }
)

export const toVesselTypeOptions = (fleetDataItem: VesselTypeWithCount): MultiSelectOptions => (
    {
        name: `${fleetDataItem.vesselType} (${fleetDataItem.count.toString()})`,
        value: fleetDataItem.vesselType
    }
)

export const toDefaultSelection = (item: string): MultiSelectOptions => (
    {
        name: item,
        value: item
    }
)

export const arrayIntersection = (array1: any[], array2: any[]) => {
    return array1.filter(item => array2.includes(item));
}

export const getDwtValues = (targetToCopyTo: number, sourceToCopyFrom: number, filterState: AdvancedFilterSelectionState) => {
    const targetDwtLimit = filterState.background[targetToCopyTo].dwt.limit;
    const sourceDwtValues = filterState.background[sourceToCopyFrom].dwt.data;
    const sourceDwtLimit = filterState.background[sourceToCopyFrom].dwt.limit;
    // Default the target variable to the current target values, so if we don't override them here, they remain at their current setting
    const targetDwtValues = filterState.background[targetToCopyTo].dwt.data;

    return getTargetRangeValuesFromSource(targetDwtLimit, sourceDwtValues, sourceDwtLimit, targetDwtValues);
}

export const getTeuValues =  (targetToCopyTo: number, sourceToCopyFrom: number, filterState: AdvancedFilterSelectionState) => {
    const targetTeuLimit = filterState.background[targetToCopyTo].teu.limit;
    const sourceTeuValues = filterState.background[sourceToCopyFrom].teu.data;
    const sourceTeuLimit = filterState.background[sourceToCopyFrom].teu.limit;
    // Default the target variable to the current target values, so if we don't override them here, they remain at their current setting
    const targetTeuValues = filterState.background[targetToCopyTo].teu.data;

    return getTargetRangeValuesFromSource(targetTeuLimit, sourceTeuValues, sourceTeuLimit, targetTeuValues);
}

export const getTargetRangeValuesFromSource = (targetLimit: [number, number], sourceValues: [number, number], sourceLimit: [number, number], targetValues: [number, number]) => {
    const sourceLowerBound = sourceLimit[0];
    const sourceUpperBound = sourceLimit[1];

    const targetLowerBound = targetLimit[0];
    const targetUpperBound = targetLimit[1];

    if (sourceValues[1] > targetUpperBound) {
        targetValues[1] = targetUpperBound;
    }

    if (sourceValues[1] <= targetUpperBound && sourceValues[1] !== sourceUpperBound) {
        targetValues[1] = sourceValues[1];
    }

    if (sourceValues[1] === sourceUpperBound) {
        targetValues[1] = targetUpperBound;
    }

    if (sourceValues[0] < targetLowerBound) {
        targetValues[0] = targetLowerBound;
    }

    if (sourceValues[0] >= targetLowerBound && sourceValues[0] !== sourceLowerBound) {
        targetValues[0] = sourceValues[0];
    }

    if (sourceValues[0] === sourceLowerBound) {
        targetValues[0] = targetLowerBound;
    }

    const targetValuesMatchTargetLimits = targetValues[0] === targetLowerBound && targetValues[1] === targetUpperBound;
    const changed = !targetValuesMatchTargetLimits;

    return {
        data: targetValues,
        changed
    }
}

export const toSelectionValue = (item: MultiSelectOptions): string =>
    item.value

export const getFleetTeuRange = (inspectionAndGradeDataResponse: InspectionAndGradeDataResponse, min: number, defaultMax: number): [number, number] => {
    let teuMax = defaultMax;

    const teuValues = inspectionAndGradeDataResponse.fleetDataItems.map((item) => {  return item.vessel.teu  }).filter(Boolean)
    teuMax = teuValues.length > 0 ? Math.max(...teuValues) : defaultMax;

    return [min, Math.ceil(teuMax / 100) * 100];
}

export const getTeuRangeText = (t: any, range: [number, number], localeSentence: string = "benchmarkModal.teuRangeSentence") => {
    const minValue = range[0].toLocaleString(undefined, {maximumFractionDigits: 2})
    const maxValue = range[1].toLocaleString(undefined, {maximumFractionDigits: 2})
    return t(localeSentence, {min: minValue, max: maxValue});
}

export const getDefaultFleetTeuState = (
    previousState: RangeFilterState,
    inspectionAndGradeData: InspectionAndGradeDataResponse,
    defaultMinValue: number,
    defaultMaxValue: number,
): RangeFilterState  => (
    {
        ...previousState,
        data: getFleetTeuRange(inspectionAndGradeData, defaultMinValue, defaultMaxValue),
        limit: getFleetTeuRange(inspectionAndGradeData, defaultMinValue, defaultMaxValue),
        changed: false,
    }
);

export const getDefaultMarketTeuState = (
    previousState: RangeFilterState,
    defaultMinValue: number,
    defaultMaxValue: number,
    marketDataFilteringOptions?: MarketDataFilteringOptions,
): RangeFilterState => (
    {
        ...previousState,
        data: [defaultMinValue, marketDataFilteringOptions?.teu.max ?? defaultMaxValue],
        limit: [
            defaultMinValue,
            marketDataFilteringOptions?.teu.max ?? defaultMaxValue
        ],
        changed: false,
    }
)

export const defaultFleetInspectionDateRangeOptions = (t: any) => {
    const inspectionDateRangeOptions = [
        {
            name: `${t("inspectionDateDropdown.allTime")}`, 
            key: GlobalDateRange.ALL_TIME,
            checked: true,
            value: GlobalDateRange.ALL_TIME,
            default: true
        },
        {
            name: `${t("inspectionDateDropdown.lastSixMonths")}`, 
            key: GlobalDateRange.LAST_SIX_MONTHS,
            checked: false,
            value: GlobalDateRange.LAST_SIX_MONTHS,
            default: false
        },
        {
            name: `${t("inspectionDateDropdown.lastYear")}`, 
            key: GlobalDateRange.LAST_YEAR,
            checked: false,
            value: GlobalDateRange.LAST_YEAR,
            default: false
        },
        {
            name: `${t("inspectionDateDropdown.lastFiveYears")}`, 
            key: GlobalDateRange.LAST_FIVE_YEARS,
            checked: false,
            value: GlobalDateRange.LAST_FIVE_YEARS,
            default: false
        }
    ] as Array<SingleSelectItem>;

    return inspectionDateRangeOptions;
}

export const getDefaultFleetInspectionDateState = (
    previousState: SingleSelectFilterState,
    t: any
): SingleSelectFilterState => {
    return {
        ...previousState,
        data: defaultFleetInspectionDateRangeOptions(t),
    }
}

export const generateNewSingleSelectState = (
    updateItemKey: number,
    options: SingleSelectItem[]
): SingleSelectItem[] => {
    return options.map((option) => ({
      ...option,
      checked: option.key === updateItemKey,
    }));
}

export const getSingleSelectCount = (selectionState: Array<SingleSelectItem>) =>
    selectionState.length > 0 && selectionState.find(e => e.checked)?.key !== selectionState.find(e => e.default)?.key ? 1 : 0;

export const getMinTeuRange = (teuRange: RangeFilterState) => {
    if (!teuRange?.data) {
        return 0;
    }

    return teuRange.data[0] === 0 ? 1 : teuRange.data[0];
}

