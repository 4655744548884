import { getFleetToken } from "../../services/FleetDataService";
import {
    setFleetDataItems,
    setError,
    setLoading,
    setData,
    setStatTileConfigs
} from "../slices/fleetSlice";
import {
    setLoadingList,
    setSelectedList,
    setUserDetails,
    setUserLists,
    setUserToken
} from "../slices/userSlice";
import { AppThunk, persistor } from "../store";
import {
    addCustomColumnData,
    addDefaultScheduledDates,
    addSpecialColumnData,
    decodeFleetToken
} from "../../utils/UserHelper";
import { getUserDetails } from "../../services/UserService";
import {
    selectSelectedList,
    selectUserHasCapability,
    selectUserDetails,
    selectUserLists
} from "../selectors/userSelectors";
import { getShipDetails } from "../../services/ShipService";
import { fetchDataByPath } from "./initThunks";
import { clearAllFilters } from "./filtersThunks";
import {
    setLoading as setDefectsLoading,
    setSelectedVessel,
    setFilteredDefects
} from "../slices/defectSlice";
import {
    setLoading as setFleetGraphLoading,
    setSubgradeLoading
} from "../slices/fleetGraphSlice";
import { setLoading as setPSCLoading } from "../slices/pscSlice";
import { 
    setClosedRatioLoading,
    setAgeOfOpenDefectsLoading,
    setCumulativeDefectLoading
} from "../slices/defectInsightsSlice";
import { FleetDataItem } from "../../types/FleetDataItem";
import { updateGroupList } from "../../services/GroupService";
import { fetchTokenData } from "./tokenThunks";
import { Capability } from "../../types/Capability";

export const fetchFleetData =
    (): AppThunk<Promise<boolean>> => async (dispatch, getState) => {
        dispatch(setLoading(true));
        dispatch(setError(false));

        try {
            const userLists = selectUserLists(getState());

            if (!userLists) {
                await dispatch(fetchFleetToken());
            }

            await dispatch(fetchUserDetails());

            await dispatch(fetchShipDetails());

            dispatch(setLoading(false));
        } catch (e) {
            console.error(e);
            dispatch(setError(true));
        }

        return true;
    };

export const fetchFleetToken =
    (): AppThunk<Promise<boolean>> => async (dispatch) => {
        try {
            const response = await getFleetToken();

            if (response) {
                dispatch(setUserToken(response));
                const userLists = decodeFleetToken(response);

                dispatch(setUserLists(userLists));

                const selected = userLists.lists.find((list) => list.selected);
                dispatch(setSelectedList(selected));
            } else {
                throw Error("no data");
            }
        } catch (e) {
            console.error(e);
        }

        return true;
    };

export const fetchUserDetails =
    (): AppThunk<Promise<boolean>> => async (dispatch, getState) => {
        try {
            const state = getState();
            const userDetails = selectUserDetails(state);
            const userLists = selectUserLists(state);

            const group = userLists?.group;

            if (!userDetails && group) {
                const response = await getUserDetails(group.id);

                if (response) {
                    let groupData = {};

                    if (response.groupData?.length) {
                        groupData = response.groupData[0];
                    }

                    const mappedUserDetails = {
                        ...response,
                        groupDetails: {
                            ...groupData,
                            name: group.name
                        }
                    };

                    dispatch(setUserDetails(mappedUserDetails));
                } else {
                    throw Error("no data");
                }
            }
        } catch (e) {
            console.error(e);
        }

        return true;
    };

export const fetchShipDetails =
    (): AppThunk<Promise<boolean>> => async (dispatch, getState) => {
        const state = getState();
        const hasCapability = selectUserHasCapability(
            state,
            Capability.CAP_FLEETAPP_VIEW_FLEETS
        );
        
        if (hasCapability) {
            const selectedList = selectSelectedList(state);
            const userDetails = selectUserDetails(state);
            try {
                if (selectedList) {
                    const response = await getShipDetails(selectedList.vessels);
    
                    if (response) {
                        const fleetDataItems: FleetDataItem[] = response.map(
                            (ship: any) => ({
                                vessel: {
                                    imo: `${ship.imo}`,
                                    vesselName: ship.vessel_name,
                                    type: ship.vessel_type,
                                    // subtype: ship.shiptype_level_2,
                                    technicalManager: ship.technical_manager,
                                    classSociety: ship.classification_society,
                                    flag: ship.flag_state,
                                    dwt: ship.deadweight,
                                    buildYear: ship.year_of_build,
                                    shipAge: Number(ship.ship_age),
                                    countryOfBuild: ship.build_country,
                                    teu: Number(ship.teu),
                                    riskLevel: 0,
                                    notes: "",
                                    group: "",
                                    specialSurveyDate: undefined
                                },
                                inspection: {}
                            })
                        );
    
                        if (userDetails?.groupDetails) {
                            const scheduledData = userDetails.scheduledData?.length
                                ? userDetails.scheduledData[0].vessels
                                : undefined;
                            
                            const fleetDataItemsWithColumnData = fleetDataItems.map(
                                (item) => {
                                    const fleetDataItem = { ...item };
                                    const vessel = { ...fleetDataItem.vessel };
    
                                    addSpecialColumnData(
                                        userDetails.groupDetails,
                                        vessel
                                    );
                                    addCustomColumnData(
                                        userDetails.groupDetails,
                                        fleetDataItem,
                                        vessel
                                    );
                                    addDefaultScheduledDates(
                                        scheduledData,
                                        fleetDataItem
                                    )
    
                                    return {
                                        ...fleetDataItem,
                                        vessel
                                    };
                                }
                            );
    
                            dispatch(
                                setData({
                                    fleetDataItems: fleetDataItemsWithColumnData,
                                    userDetails
                                })
                            );
                            dispatch(
                                setFleetDataItems(fleetDataItemsWithColumnData)
                            );
                        }
                    } else {
                        throw Error("no data");
                    }
                }
            } catch (e) {
                console.error(e);
            }   
        }

        return true;
    };

export const selectList =
    (listId: string): AppThunk =>
    async (dispatch, getState) => {
        dispatch(setLoadingList(true));

        try {
            const state = getState();
            const userLists = selectUserLists(state);
            const selectedList = userLists?.lists.find((list) => list.id === listId);

            if (userLists && selectedList) {
                persistor.pause();
                dispatch(setStatTileConfigs(undefined));
                dispatch(clearAllFilters());
                dispatch(setSelectedVessel(""));
                dispatch(setLoading(true));
                dispatch(setFleetGraphLoading(true));
                dispatch(setSubgradeLoading(true));
                dispatch(setDefectsLoading(true));
                dispatch(setPSCLoading(true));
                dispatch(setClosedRatioLoading(true));
                dispatch(setAgeOfOpenDefectsLoading(true));
                dispatch(setCumulativeDefectLoading(true));
                dispatch(setFilteredDefects([]));

                const promises = [
                    updateGroupList(userLists.group.id, selectedList.id, {
                        selected: true
                    })
                ];

                const previouslySelected = userLists.lists.find(
                    (list) => list.selected
                );

                if (previouslySelected) {
                    promises.push(
                        updateGroupList(
                            userLists.group.id,
                            previouslySelected.id,
                            { selected: false }
                        )
                    );
                }

                await Promise.all(promises);

                await dispatch(fetchFleetToken());

                await dispatch(fetchDataByPath(false, true));

                dispatch(fetchTokenData())

                persistor.persist();
            }
        } catch (e) {
            console.error(e);
        }

        dispatch(setLoadingList(false));
    };
