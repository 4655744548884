import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";
import { Message } from "primereact/message";
import {ReactElement, useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";
import { requestInspection } from "../../services/RequestInspection";
import { UserDetails } from "../../types/UserDetails";
import { RequestInspectionInfo } from "../../types/RequestInspectionInfo";
import { errorToast } from "@idwal/idwal-react-components";
import { MultiSelect, MultiSelectChangeEvent } from 'primereact/multiselect';
import { sendGAEvent } from "../../AnalyticsService";
import { INSPECTION_REQUESTED, VESSEL_VIEW } from "../../constants/analytics";
import { useAppDispatch, useAppSelector } from "../../hooks/storeHooks";
import { selectUserDetails } from "../../store/selectors/userSelectors";
import { selectRequestInspectionDialog } from "../../store/selectors/fleetSelectors";
import { setRequestInspectionDialog } from "../../store/slices/fleetSlice";
import { FleetDataItem } from "../../types/FleetDataItem";

export function getAccountEmailAndGroupName(userDetails: UserDetails | undefined) {
    let accountManagerEmail: string | undefined;
    let groupNames: string | undefined;

    if(userDetails) {
        try {
            groupNames = userDetails.groupDetails.name;
            accountManagerEmail = userDetails.groupDetails.accountManager.email;
        } catch (err) {
            console.error(err);
        }
    }
    return {accountManagerEmail, groupNames };
}

export const RequestInspectionDialog = () => {
    const { t } = useTranslation("locale");
    const dispatch = useAppDispatch();

    const { show, vessels, fromVesselView } = useAppSelector(selectRequestInspectionDialog);
    const userDetails = useAppSelector(selectUserDetails);

    const [selectedVessels, setSelectedVessels] = useState<FleetDataItem[]>([]);
    const [requestInspectionComment, setRequestInspectionComment] = useState<string>();
    const [showRequestConfirmation, setShowRequestConfirmation] = useState(false);
    const [submitButtonDisabled,setSubmitButtonDisabled] = useState<boolean>(false);

    useEffect(() => {
        setSelectedVessels(vessels);
    }, [vessels])

    const requestInspectionClose = () => {
        dispatch(setRequestInspectionDialog({
            show: false,
            vessels: [],
            fromVesselView: false,
        }))
        setRequestInspectionComment("");
        setShowRequestConfirmation(false);
    };

    const sendInspectionRequest = async () => {
        if (!userDetails?.userInfo) {
            console.info("Unable to request inspection for user - missing user details.");
            return;
        }

        setSubmitButtonDisabled(true);
        const vesselNames: string[] = [];
        const vesselImos: string[] = [];

        selectedVessels.forEach((vessel) => {
            vesselImos.push(vessel.vessel.imo);
            vesselNames.push(vessel.vessel.vesselName)
        });

        const { accountManagerEmail, groupNames } = getAccountEmailAndGroupName(userDetails);

        const inspectionRequest : RequestInspectionInfo = {
            vesselNames: vesselNames,
            vesselImos: vesselImos,
            comment: requestInspectionComment ?? "",
            organisation: userDetails.userOrganization,
            name: userDetails.userInfo?.name,
            email: userDetails.userInfo?.email,
            toEmail: accountManagerEmail,
            groupName: groupNames
        };

        try {
            await requestInspection(inspectionRequest);
            if (fromVesselView) {
                sendGAEvent(VESSEL_VIEW, INSPECTION_REQUESTED);
            }

            setShowRequestConfirmation(true);
        } catch (err) {
            requestInspectionClose();
            errorToast(t("requestInspection.errorMessage"));
        }

        setSubmitButtonDisabled(false);
        setRequestInspectionComment("");
    };


    const requestInspectionDialogFooter = ():ReactElement => {
        return (
            showRequestConfirmation
            ? <div>
                <div className="flex justify-content-center flex-wrap">
                <div className="flex align-items-center justify-content-center">
                    <Button
                        label={t("requestInspection.closeButton")}
                        className="p-button-text md:w-10rem"
                        onClick={() => requestInspectionClose()}
                        data-cy="request-close" />
                </div>
                </div>
            </div>
            : <div className="flex justify-content-center flex-wrap">
                <div className="flex align-items-center justify-content-center">
                    <Button
                        label={t("requestInspection.cancelButton")}
                        className="p-button-text md:w-8rem"
                        onClick={() => requestInspectionClose()}
                        data-cy="request-cancel" />
                    <Button
                        disabled={submitButtonDisabled}
                        label={t("requestInspection.requestButton")}
                        onClick={sendInspectionRequest}
                        autoFocus
                        data-cy="request-submit" />
                </div>
            </div>
        );
    };

    const value = vessels.map((vessel) => `${vessel.vessel.vesselName} ${vessel.vessel.imo}`);

    const options = selectedVessels.map((vessel) => `${vessel.vessel.vesselName} ${vessel.vessel.imo}`);

    const onChange = (event: MultiSelectChangeEvent) => {
        setSelectedVessels(selectedVessels.concat([event.value]));
    }

    return (
        <Dialog
            className="dialog-medium"
            style={{minHeight: "430px"}}
            visible={show}
            onHide={requestInspectionClose}
            header={t("requestInspection.title")}
            closable={true}
            blockScroll={true}
            headerClassName="font-semibold header-text"
            footer={requestInspectionDialogFooter}
            data-cy="request-dialog"
            draggable={false}>
            {showRequestConfirmation
            ? <div className="p-fluid">
                <Message className="justify-content-start" severity="success" text={t("requestInspection.successMessage")} data-cy="request-confirm-msg"></Message>
            </div>
            : <div className="field grid p-fluid">
                <label className="flex align-items-center col-12 md:col-3 mt-2 mb-0 md:mb-2">Vessel</label>

                <div className="col-12 md:col-9 pb-2 pt-0 md:pt-3">
                    <MultiSelect
                        value={value}
                        onChange={onChange}
                        options={options}
                        display="comma"
                        maxSelectedLabels={5}
                        data-cy="request-vessel"
                         />
                </div>
                <label className="flex align-items-start col-12 md:col-3 mb-0 md:mb-2">Comments</label>
                <div className="col-12 md:col-9 pb-2 pt-0 md:pt-2">
                    <InputTextarea rows={8} value={requestInspectionComment} onChange={(e) => setRequestInspectionComment(e.target.value)} data-cy="request-comment"></InputTextarea>
                </div>
            </div>}
        </Dialog>
    );
};
