import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TablePageState } from '../../types/Table';
import { PSCDeficiency, PSCInspection, PSCSummary, PSCTabs, PSCTimePeriod } from '../../types/psc';
import { DataTableFilterMeta } from 'primereact/datatable';
import { pscDeficienciesFilters, pscInspectionFilters } from '../../utils/PSCTableHelper';

export interface PSCState {
    inspectionsData: PSCInspection[];
    deficienciesData: PSCDeficiency[];
    filteredInspections: PSCInspection[];
    filteredDeficiencies: PSCDeficiency[];
    summaryData: PSCSummary[];
    loading: boolean;
    error: boolean;
    activeTab: number;
    distinctMOUs: string[];
    globalFilterValue: string;
    globalFilteredIMOs: number[];
    inspectionsFilters: DataTableFilterMeta;
    deficienciesFilters: DataTableFilterMeta;
    performancePage: TablePageState;
    inspectionsPage: TablePageState;
    deficienciesPage: TablePageState;
    timePeriod: string; 
}

export const initialState: PSCState = {
    inspectionsData: [],
    deficienciesData: [],
    filteredInspections: [],
    filteredDeficiencies: [],
    summaryData: [],
    loading: true,
    error: false,
    distinctMOUs: [],
    activeTab: PSCTabs.performance,
    globalFilterValue: "",
    globalFilteredIMOs: [],
    inspectionsFilters: pscInspectionFilters,
    deficienciesFilters: pscDeficienciesFilters,
    performancePage: { row: 25, first: 0 },
    inspectionsPage: { row: 25, first: 0 },
    deficienciesPage: { row: 25, first: 0 },
    timePeriod: PSCTimePeriod[0],
};

export const pscSlice = createSlice({
    name: 'psc',
    initialState,
    reducers: {
        setInspectionsData: (state, action: PayloadAction<PSCInspection[]>) => {
            state.inspectionsData = action.payload;
        },
        setDeficienciesData: (state, action: PayloadAction<PSCDeficiency[]>) => {
            state.deficienciesData = action.payload;
        },
        setFilteredInspections: (state, action: PayloadAction<PSCInspection[]>) => {
            state.filteredInspections = action.payload;
        },
        setFilteredDeficiencies: (state, action: PayloadAction<PSCDeficiency[]>) => {
            state.filteredDeficiencies = action.payload;
        },
        setSummaryData: (state, action: PayloadAction<PSCSummary[]>) => {
            state.summaryData = action.payload;
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setError: (state, action: PayloadAction<boolean>) => {
            state.error = action.payload;
        },
        setDistinctMOUs: (state, action: PayloadAction<string[]>) => {
            state.distinctMOUs = action.payload;
        },
        setActiveTab: (state, action: PayloadAction<number>) => {
            state.activeTab = action.payload;
        },
        setGlobalFilterValue: (state, action: PayloadAction<string>) => {
            state.globalFilterValue = action.payload;
        },
        setGlobalFilteredIMOs: (state, action: PayloadAction<number[]>) => {
            state.globalFilteredIMOs = action.payload;
        },
        setInspectionsFilters: (state, action: PayloadAction<DataTableFilterMeta>) => {
            state.inspectionsFilters = action.payload;
        },
        setDeficienciesFilters: (state, action: PayloadAction<DataTableFilterMeta>) => {
            state.deficienciesFilters = action.payload;
        },
        resetFilters: (state) => {
            state.globalFilterValue = "";
            state.inspectionsFilters = pscInspectionFilters;
            state.deficienciesFilters = pscDeficienciesFilters;
            state.timePeriod = PSCTimePeriod[0];
        },
        setPerformancePage: (state, action: PayloadAction<TablePageState>) => {
            state.performancePage = action.payload;
        },
        setInspectionsPage: (state, action: PayloadAction<TablePageState>) => {
            state.inspectionsPage = action.payload;
        },
        setDeficienciesPage: (state, action: PayloadAction<TablePageState>) => {
            state.deficienciesPage = action.payload;
        },
        setTimePeriod: (state, action: PayloadAction<string>) => {
            state.timePeriod = action.payload;
        },
    }
});

export const {
    setInspectionsData,
    setDeficienciesData,
    setFilteredInspections,
    setFilteredDeficiencies,
    setSummaryData,
    setLoading,
    setError,
    setDistinctMOUs,
    setActiveTab,
    setGlobalFilterValue,
    setGlobalFilteredIMOs,
    setInspectionsFilters,
    setDeficienciesFilters,
    resetFilters,
    setPerformancePage,
    setInspectionsPage,
    setDeficienciesPage,
    setTimePeriod,
} = pscSlice.actions;

export default pscSlice.reducer;
