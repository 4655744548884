import { getTranslationObject } from "../../../services/Translation";
import LOCAL_TABLE_CONFIG_KEY from "../../../types/TableLocalConfigKeys";
import { getLocalStorageOrderedColumns } from "../../../utils/TableUtils";
import { buildColumnConfig } from "../../Shared/TableFunctions"
import { textEditorTemplate, riskLevelEditorTemplate } from "../FilterTemplates";
import { FleetTableFields } from "../FleetTableFields"
import { onNotesEditComplete, onRiskEditComplete } from "../Utils";
import {SharedTableColumnConfig} from "../../../types/SharedTableColumnConfig";
import { FilterMatchMode } from "primereact/api";
import { FleetDataItem } from "../../../types/FleetDataItem";
const t = getTranslationObject();

export const COLUMN_DATA_TYPES = {
    text: "text",
    numeric: "numeric"
}

const vesselTypeConfig = () => {
    return buildColumnConfig(
        FleetTableFields.VESSEL_TYPE,
        t("fleetTable.vesselType"),
        "text",
        { filterField: FleetTableFields.VESSEL_TYPE, filterPlaceholder: t("fleetTable.searchByType"), filterMatchMode: FilterMatchMode.IN }
    );
}

const technicalManagerConfig = () => {
    return buildColumnConfig(
        FleetTableFields.VESSEL_TECHNICAL_MANAGER, 
        t("fleetTable.technicalManager"), 
        "text",
        { filterField: FleetTableFields.VESSEL_TECHNICAL_MANAGER, filterMatchMode: FilterMatchMode.IN }
    );
}

const daysSinceInspectionConfig = () => {
    return buildColumnConfig(
        FleetTableFields.INSPECTION_DAYS_SINCE, 
        t("fleetTable.daysSinceInspected"), 
        "numeric",
        { filterField: FleetTableFields.INSPECTION_DAYS_SINCE, showFilterMenu: false, showFilterMatchModes: true },
        { justifyContent: "center", textAlign: "center" }
    );
}

const riskLevelConfig = (
    updateRowItem: (item: FleetDataItem) => void,
    onComplete: (data: Record<string, unknown>) => void
) => {
    return buildColumnConfig(
        FleetTableFields.RISK_LEVEL, 
        t("fleetTable.riskLevel"), 
        "text", 
        { filterField: FleetTableFields.RISK_LEVEL, filterPlaceholder: t("fleetTable.searchByType"), filterMatchMode: FilterMatchMode.IN }, 
        undefined, 
        false,
        {
            onCellEditComplete : (e : any) => onRiskEditComplete(e, updateRowItem, onComplete),
            editor : riskLevelEditorTemplate
        }
    );
}

const idwalGradeConfig = () => {
    return buildColumnConfig(
        FleetTableFields.GRADE_FILTER_FIELD, 
        t("fleetTable.idwalGrade"),
       "numeric",
        { filterField: FleetTableFields.GRADE_FILTER_FIELD, showFilterMenu: false, showFilterMatchModes: true },
        { justifyContent: "center" }
    );
}

const trendConfig = () => {
    return buildColumnConfig(
        FleetTableFields.TRENDING,
        t("fleetTable.trending"),
        "numeric",
        { filterField: FleetTableFields.TRENDING, filterPlaceholder: t("fleetTable.searchByType"), filterMatchMode: FilterMatchMode.IN },
        { justifyContent: "center" }
    );
}

const conditionGradeConfig = () => {
    return buildColumnConfig(
        FleetTableFields.INSPECTION_CONDITION_SCORE, 
        t("fleetTable.conditionGrade"), 
        "numeric",
        { filterField: FleetTableFields.INSPECTION_CONDITION_SCORE, showFilterMenu: false, showFilterMatchModes: true },
        { justifyContent: "center", textAlign: "center" }
    );
}

const managementGradeConfig = () => {
    return buildColumnConfig(
        FleetTableFields.INSPECTION_MANAGEMENT_SCORE, 
        t("fleetTable.managementGrade"), 
        "numeric",
        { filterField: FleetTableFields.INSPECTION_MANAGEMENT_SCORE, showFilterMenu: false, showFilterMatchModes: true },
        { justifyContent: "center", textAlign: "center" }
    );
}

const inspectionDueConfig = () => {
    return buildColumnConfig(
        FleetTableFields.INSPECTION_INSPECTION_DUE, 
        t("fleetTable.inspectionDue"), 
        "date",
        { filterField: FleetTableFields.INSPECTION_INSPECTION_DUE, showFilterMenu: false, showFilterMatchModes: true },
        { justifyContent: "space-between" }
    );
}

const rankConfig = () => {
    return buildColumnConfig(
        FleetTableFields.RANK, 
        t("fleetTable.rank"), 
        "numeric",
        { filterField: FleetTableFields.RANK, showFilterMenu: false, showFilterMatchModes: true },
        { justifyContent: "center", textAlign: "center" }
    );
}

const classSocietyConfig = () => {
    return buildColumnConfig(
        FleetTableFields.VESSEL_CLASS_SOCIETY, 
        t("fleetTable.classSociety"), 
        "text",
        { filterField: FleetTableFields.VESSEL_CLASS_SOCIETY, showFilterMenu: true, showFilterMatchModes: true, filterPlaceholder: t("fleetTable.searchByClassSociety") }
    );
}

const flagConfig = () => {
    return buildColumnConfig(
        FleetTableFields.VESSEL_FLAG, 
        t("fleetTable.flag"), 
        "text",
        { filterField: FleetTableFields.VESSEL_FLAG, showFilterMenu: false, showFilterMatchModes: true, filterMatchMode: FilterMatchMode.IN }
    );
}

const dwtConfig = () => {
    return buildColumnConfig(
        FleetTableFields.VESSEL_DWT, 
        t("fleetTable.dwt"), 
        "numeric",
        { filterField: FleetTableFields.VESSEL_DWT, showFilterMenu: true, showFilterMatchModes: true, filterPlaceholder: t("fleetTable.searchByDwt") },
        { justifyContent: "center", textAlign: "center" }
    );
}

const countryOfBuildConfig = () => {
    return buildColumnConfig(
        FleetTableFields.VESSEL_COUNTRY_OF_BUILD, 
        t("fleetTable.countryOfBuild"), 
        "text",
        { filterField: FleetTableFields.VESSEL_COUNTRY_OF_BUILD, showFilterMenu: false, showFilterMatchModes: true, filterPlaceholder: t("fleetTable.searchByCountryOfBuild") },
        { justifyContent: "center", textAlign: "center" }
    );
}

const buildYearConfig = () => {
    return buildColumnConfig(
        FleetTableFields.VESSEL_BUILD_YEAR, 
        t("fleetTable.buildYear"), 
        "numeric",
        { filterField: FleetTableFields.VESSEL_BUILD_YEAR, showFilterMenu: true, showFilterMatchModes: true, filterPlaceholder: t("fleetTable.searchByBuildYear") },
        { justifyContent: "center", textAlign: "center" }
    );
}


const buildLastInspectedConfig = () => {
    return buildColumnConfig(
        FleetTableFields.LAST_INSPECTED,
        t("fleetTable.lastInspected"),
        "text",
        { filterField: FleetTableFields.LAST_INSPECTED, showFilterMenu: false, showFilterMatchModes: true },
        { justifyContent: "center", textAlign: "center" }
    );
}


const notesConfig = (
    updateRowItem: (item: FleetDataItem) => void,
    onComplete: (data: Record<string, unknown>) => void
) => {
    return buildColumnConfig(
        FleetTableFields.VESSEL_NOTES, 
        t("fleetTable.notes"), 
        "text",
        {
            filterField: FleetTableFields.VESSEL_NOTES,
            showFilterMenu: true,
            showFilterMatchModes: true,
            filterPlaceholder: t("fleetTable.searchNotes") },
        { justifyContent: "center" },
        false,
        {
            onCellEditComplete : (e : any) => onNotesEditComplete(e, updateRowItem, onComplete),
            editor : textEditorTemplate
        }
    );
}

export const specialSurveyDateConfig = () => {
    return buildColumnConfig(
        FleetTableFields.VESSEL_SPECIAL_SURVEY_DATE, 
        t("fleetTable.specialSurveyDate"), 
        "date",
        { filterField: FleetTableFields.VESSEL_SPECIAL_SURVEY_DATE, showFilterMenu: false, showFilterMatchModes: true },
        { justifyContent: "space-between" }
    );
}

export const vesselInspectionStatusConfig = () => {
    return buildColumnConfig(
        FleetTableFields.VESSEL_INSPECTION_STATUS,
        t("fleetTable.inspectionStatus"),
        "text",
        { filterField: FleetTableFields.VESSEL_INSPECTION_STATUS, showFilterMenu: false, showFilterMatchModes: true }
    )
}


const addFunctionsToColumn = (
    col: SharedTableColumnConfig,
    updateRowItem: (item: FleetDataItem) => void,
    onComplete: (data: Record<string, unknown>) => void
) => {
    switch (col.field) {
        case FleetTableFields.RISK_LEVEL:
            col.onCellEditComplete = (e : any) => onRiskEditComplete(e, updateRowItem, onComplete);
            col.editor = riskLevelEditorTemplate;
            break;
        case FleetTableFields.VESSEL_NOTES:
            col.onCellEditComplete = (e : any) => onNotesEditComplete(e, updateRowItem, onComplete);
            col.editor = textEditorTemplate;
            break;
        default:
            break;
    }
}

export const getDefaultFleetColumnsConfig = (
    updateRowItem: (item: FleetDataItem) => void,
    onComplete: (data: Record<string, unknown>) => void
) => {
    const columns =  [
        buildLastInspectedConfig(),
        idwalGradeConfig(),
        trendConfig(),
        inspectionDueConfig(),
        vesselInspectionStatusConfig(),
        technicalManagerConfig(),
        daysSinceInspectionConfig(),
        vesselTypeConfig(),
        notesConfig(updateRowItem, onComplete),
        riskLevelConfig(updateRowItem, onComplete),
        conditionGradeConfig(),
        managementGradeConfig(),
        rankConfig(),
        classSocietyConfig(),
        flagConfig(),
        dwtConfig(),
        countryOfBuildConfig(),
        buildYearConfig()

    ];

    const localOrderedColumnsConfig = getLocalStorageOrderedColumns(LOCAL_TABLE_CONFIG_KEY.FLEET_ORDERED_COLUMNS, columns);
    if (localOrderedColumnsConfig)
    {
        localOrderedColumnsConfig.forEach((col: SharedTableColumnConfig) => {
            addFunctionsToColumn(col, updateRowItem, onComplete);
        });

        return localOrderedColumnsConfig;
    }

    return columns;
};